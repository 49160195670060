import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Divider, Radio, RadioChangeEvent, Space } from 'antd';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import { setSpecificTab } from '../../../../store/tab/tabSlice';
import {
  setOriginWizard,
  setAdditionalParametersWizard,
  resetAdditionalParametersWizard,
  setSourceWizard,
  setMarsBrandsWizard,
  collapseAllWizards,
} from '../../../../store/wizzard/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import { D2C_WEBSITE_DATA, MARKETING_CAMPAIGNS } from '../AdditionalParametersCollapse/const';
import CollapseHeader from '../CollapseHeader';
import { ICollapseProps } from '../types';
import { selectWizardOriginByTabName } from '../../../../store/wizzard/selectors';
import { EPartyData, EPartyDataToName } from './const';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectCategoryByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectOriginByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { AUDIENCE_ID, options, stepErrorDescription } from '../const';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { useBrandMarketingCampaignsLazyQuery, useGetBrandsQuery } from '../../../../apollo/graphql-types';
import sortBy from 'lodash/sortBy';
import { getBrandsByCategoryId, isMarsManufacturer } from '../../../../utils/brand.utils';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { handleScrollIntoView } from '../utils';

interface IOriginCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const OriginCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IOriginCollapseProps ) => {
  const dispatch = useDispatch();
  const { data: marsBrandsData, loading: isMarsBrandsLoading, error: errorMarsBrands, refetch: refetchMarsBrands } = useGetBrandsQuery(
    {
      ...options(),
      notifyOnNetworkStatusChange: true,
    }
  );
  const [loadCampaigns, {
    data: campaignsData,
    loading: isCampaignsLoading,
    error: errorCampaigns,
    refetch: refetchCampaigns,
  }] = useBrandMarketingCampaignsLazyQuery( { ...options(), notifyOnNetworkStatusChange: true } );

  const originWizard = useSelector( selectWizardOriginByTabName( activeTab ) );
  const isOnlyThirdParty = !campaignsData?.brandMarketingCampaigns.no && !campaignsData?.brandMarketingCampaigns.yes;
  const selectedOrigin = useSelector( selectOriginByAudienceId( activeTab ) );
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const campaignsBrandNames = uniqBy(
    flatten( [
      ...( campaignsData?.brandMarketingCampaigns.yes || [] ),
      ...( campaignsData?.brandMarketingCampaigns.no || [] ),
    ] ), 'brandName'
  ).map( ( campaign ) => campaign.brandName ).join( ', ' );

  const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData?.categorizedBrands || [] );

  const scrollRef = useRef<HTMLDivElement | null>( null );
  useEffect( () => {
    loadCampaigns( {
      variables: {
        brands: sortBy( availableBrands
          ?.filter( ( brand ) => selectedBrands.includes( brand.brandId ) )
          .map( ( brand ) => ( { id: brand.brandId, name: brand.brandName } ) ), 'id' ),
      },
    } );
  }, [marsBrandsData] );

  useEffect( () => {
    const errorMessage = errorCampaigns?.message ?? errorMarsBrands?.message;

    if ( !!errorMessage ) {
      console.error(`Loading data error: ${errorMessage}`); //eslint-disable-line
    }
  }, [errorCampaigns, errorMarsBrands] );

  const setOrigin = ( parameter: EPartyData ) => {
    const origin = parameter === EPartyData.firstParty
      ? {
        firstParty: true,
        thirdParty: undefined,
      }
      : {
        firstParty: undefined,
        thirdParty: true,
      };

    dispatch( setAudienceParameters( [activeTab, {
      ...origin,
      marketingCampaigns: undefined,
      engagementStatus:   undefined,
      d2c:                undefined,
      buyerTypes:         undefined,
    }] ) );
  };

  // When we change value in 'Origin' Button Group
  const handleRadioGroupChange = ( e: RadioChangeEvent ) => {
    handleScrollIntoView( scrollRef );

    dispatch(
      setOriginWizard( {
        isFinished: false,
        activeTab,
      } ),
    );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    setOrigin( e.target.value );
  };

  // When we click 'Finish Wizard' after 'First-Party Data' choice
  const handleFinishWizardClick = ( parameter: EPartyData ) => {
    setOrigin( parameter );

    dispatch(
      setOriginWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( resetAdditionalParametersWizard( { activeTab } ) );
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  // When we click 'Next - Additional Parameters' after 'First-Party Data' choice
  const handleAdditionalParametersClick = ( parameter: EPartyData ) => {
    setOrigin( parameter );

    dispatch(
      setOriginWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( setAdditionalParametersWizard( { isExpand: true, isVisible: true, activeTab } ) );
    dispatch( setSourceWizard( { activeTab, isExpand: false } ) );
    dispatch( setMarsBrandsWizard( { activeTab, isExpand: false } ) );
  };

  useEffect( () => {
    if ( !originWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [originWizard.isFinished] );

  useEffect( () => {
    if ( isOnlyThirdParty || selectedOrigin !== EPartyData.none ) {
      handleScrollIntoView( scrollRef );
    }
  }, [selectedOrigin, isOnlyThirdParty] );

  useEffect( () => {
    dispatch( setOriginWizard( { isLoading: isCampaignsLoading, activeTab } ) );

    if ( !isCampaignsLoading ) {
      handleScrollIntoView( scrollRef );
    }
  }, [isCampaignsLoading] );

  useEffect( () => {

    if ( hasAudiencesToCompare && isEqualComparisons ) {
      const audience = audiencesToCompare[activeTab];
      const isMarsBrandsSource = isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isMarsBrandsSource ) {
        dispatch(
          setOriginWizard( {
            activeTab,
            isFinished: true,
          } ),
        );

        if ( !audience.audienceParameters?.marketingCampaigns && !audience.audienceParameters?.d2c ) {
          dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
        } else {
          if ( !!audience.audienceParameters?.marketingCampaigns ) {
            dispatch( setAdditionalParametersWizard( { activeTab, value: MARKETING_CAMPAIGNS, isVisible: true } ) );
          }
          if ( !!audience.audienceParameters?.d2c ) {
            dispatch( setAdditionalParametersWizard( { activeTab, value: D2C_WEBSITE_DATA, isVisible: true } ) );
          }
        }
      }
    }
  }, [] );

  const collapseItems = [
    {
      key:   WIZARD_NAME.ORIGIN,
      label:  <CollapseHeader
        step={WIZARD_NAME.ORIGIN}
        heading={`Step 03: ${STEP_NAMES.ORIGIN}`}
        caption='Select an origin for a selected brand'
        tags={[{
          name:  TAG_VALUES.ORIGIN,
          value: selectedOrigin === EPartyData.firstParty ? EPartyDataToName.FIRST_PARTY_DATA : EPartyDataToName.THIRD_PARTY_DATA,
        }]}
      />,
      children: <>
        {isOnlyThirdParty
          ? (
            <Alert message='We have only Third-Party Data for this brand.' type='info' />
          )
          : (
            <>
              <Radio.Group
                options={[
                  { label: EPartyDataToName.FIRST_PARTY_DATA, value: EPartyData.firstParty },
                  { label: EPartyDataToName.THIRD_PARTY_DATA, value: EPartyData.thirdParty },
                ]}
                className='two-wide-button-group'
                optionType='button'
                buttonStyle='solid'
                value={selectedOrigin}
                style={{ width: '100%' }}
                onChange={handleRadioGroupChange}
              />
              {selectedBrands.length > 1 && selectedBrands.length !== campaignsBrandNames.split( ', ' ).length && (
                <div className='note-caption'>
                  <TitleWithCaption
                    caption={`*First-Party Data is available only for ${campaignsBrandNames}`}
                    captionFontSize={12}
                  />
                </div>
              )}

            </>
          )}
        <div ref={scrollRef}>
          {( selectedOrigin !== EPartyData.none || isOnlyThirdParty ) && (
            <>
              <Divider style={{ margin: '1rem 0 1rem' }} />
              <Space className='next-button'>
                <Button onClick={() => handleAdditionalParametersClick( selectedOrigin )}>Next - Additional Parameters</Button>
                <Button onClick={() => handleFinishWizardClick( selectedOrigin )}>Finish Wizard</Button>
              </Space>
            </>
          )}
        </div>
      </>,
    },
  ];

  return !!errorCampaigns || !!errorMarsBrands
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          refetchMarsBrands();
          refetchCampaigns();
        }}
      />
    )
    : isCampaignsLoading || isMarsBrandsLoading
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          items={collapseItems}
          defaultActiveKey={WIZARD_NAME.ORIGIN}
          activeKey={originWizard.isExpand ? WIZARD_NAME.ORIGIN : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setOriginWizard, originWizard )}
          expandIcon={() =>
            originWizard.isExpand ? <MinusOutlined style={expandIconStyles} /> : <PlusOutlined style={expandIconStyles} />
          }
        />
      );
};

export default OriginCollapse;
