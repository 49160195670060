import { ChartData, ChartOptions } from 'chart.js';
import { IAudienceComparisonItem } from '../../../../store/audienceComparison/typings';
import { AttributeValue } from '../../../../apollo/graphql-types';

export const colorPallette = [
  'rgba(0, 0, 160, 0.85)',
  'rgba(34, 178, 121, 0.85)',
  'rgba(122, 54, 190, 0.85)',
  'rgba(230, 160, 0, 0.85)',
  'rgba(214, 66, 0, 0.85)',
  'rgba(22, 119, 255, 0.85)',
];

export const optionsBenchmark = ( showIndex: boolean ): ChartOptions<'bar'> => ( {
  indexAxis:           'y',
  responsive:          true,
  maintainAspectRatio: false,
  normalized:          true,
  animation:           false,
  layout:              {
    padding: {
      right:  40,
      left:   showIndex ? 60 : 0,
      bottom: -20,
    },
  },
  plugins:             {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      labels: {
        percentage: {
          anchor:    'end',
          align:     'right',
          offset:    5,
          formatter: function ( value ) {
            return value.percentage > 0 ? value.percentage + '%' : 'Value is not present';
          },
          color: 'rgba(0, 0, 0, 0.88)',
        },
        ratioIndex: {
          anchor:    'start',
          align:     'left',
          offset:    8,
          formatter: function ( value ) {
            return value.ratioIndex > 0 ? value.ratioIndex : '';
          },
          font:  function ( ctx ) {
            const data = ctx.dataset.data[ctx.dataIndex] as any;
            return { weight: data.ratioIndex <= 80 || data.ratioIndex >= 120
              ? 'bold'
              : 'normal',
            };
          },
          color: function ( ctx ) {
            const data = ctx.dataset.data[ctx.dataIndex] as any;
            return data.ratioIndex <= 80
              ? 'rgb(168, 7, 26)'
              : data.ratioIndex >= 120
                ? 'rgb(35, 120, 4)'
                : 'rgba(0, 0, 0, 0.45)';
          },
        },
      },
    },
  },
  backgroundColor: ( ctx ) => {
    const raw = ctx.raw as IData;

    return raw?.backgroudColor;
  },
  scales: {
    x:             {
      min:         0,
      max:         100,
      border:      {
        display: false,
      },
      ticks: {
        display: false,
      },
      grid: {
        color:     'rgba(0, 0, 0, 0.15)',
      },
    },
    y: {
      grid:        {
        display:   false,
      },
      ticks: {
        display: false,
      },
    },
  },
} );

interface IData {
  valueName: string;
  percentage: number;
  backgroudColor: string;
}

export const generateBenchmarkData = ( attributeValues: AttributeValue[], segments: IAudienceComparisonItem[] ): ChartData<'bar'> => {

  const labels = attributeValues?.map( ( item ) => item.attributeValueName );

  const dataset: any = segments.map( ( segment, index ) =>
    ( {
      data: attributeValues?.map( ( value ) => {
        const bar = value.segmentValues.find( ( bar ) => bar.segmentId === segment.segmentId );

        return ( {
          valueName:          value.attributeValueName,
          percentage:           bar?.percentage,
          ratioIndex:           bar?.ratioIndex,
          backgroudColor: colorPallette[index],
        } as IData );
      } ),
      parsing:         {
        xAxisKey:  'percentage',
        yAxisKey: 'valueName',
      },
      barThickness:   14, // formula for barThickness -> bar height + borderWidth * 2
      borderWidth:        1.25,
      borderColor:        'transparent',
    } )
  ).filter( ( segment ) => !!segment );




  return {
    labels:   labels,
    datasets: dataset,
  };
};
