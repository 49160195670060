import isEqual from 'lodash/isEqual';
import { EPartyData } from '../../components/Blade/collapse/OriginCollapse/const';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { IAudienceComparisonSlice } from './typings';
import { createSelector } from 'reselect';
import { Entries } from '../typings';
import { selectTabs } from '../tab/selectors';

export const selectHasAudiencesToCompare = ( state: IAudienceComparisonSlice ) =>
  Object.keys( state.audienceComparison.appliedAudiencesToCompare[AUDIENCE_ID.AUDIENCE1] ).length > 1;
export const selectAppliedAudiencesToCompare = ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.appliedAudiencesToCompare;

export const selectAudiencesToCompare = ( state: IAudienceComparisonSlice ) => state.audienceComparison.audiencesToCompare;

export const selectIsEqualComparisons = ( state: IAudienceComparisonSlice ) =>
  isEqual( state.audienceComparison.audiencesToCompare, state.audienceComparison.appliedAudiencesToCompare );

export const selectFinishedTabsIsEqualComparisons = createSelector(
  selectTabs,
  selectAudiencesToCompare,
  selectAppliedAudiencesToCompare,
  ( tabs, audiencesToCompare, appliedAudiencesToCompare ) => isEqual(
    ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> ).filter( ( audience ) => tabs[audience[0]]?.isFinished ),
    Object.entries( appliedAudiencesToCompare )
  )
);
export const selectBuyerTypesByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.buyerTypes;
export const selectPreBuiltAudiencesByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.preBuiltAudienceIds;

export const selectBrandsByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.brands;

export const selectCategoryByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.category || 0;
export const selectCampaignsByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.marketingCampaigns;
export const selectD2CByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.d2c;
export const selectEngagementByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.engagementStatus;
export const selectManufacturerByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.manufacturers;
export const selectOriginByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) => {
  const firstParty = state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.firstParty;
  const thirdParty = state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.thirdParty;

  if ( firstParty === true ) {
    return EPartyData.firstParty;
  } else if ( thirdParty === true ) {
    return EPartyData.thirdParty;
  }

  return EPartyData.none;
};
export const selectAudienceComparisonOnly = ( state: IAudienceComparisonSlice ) => state.audienceComparison.stats.only;
export const selectAudienceComparisonCount = ( state: IAudienceComparisonSlice ) => state.audienceComparison.stats.only?.length;
export const selectAudienceComparisonIsLoading = ( state: IAudienceComparisonSlice ) => state.audienceComparison.isLoading;
export const selectAudienceComparisonIsAborting = ( state: IAudienceComparisonSlice ) => state.audienceComparison.isAborting;
