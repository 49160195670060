import { useEffect, useState } from 'react';
import { ITemplate } from '../AudienceComparisonEmpty/DashboardCard/DashboardCard';
import { ALL_BRANDS, ALL_BUYER_TYPES, ALL_MANUFACTURERS, AUDIENCE_ID, COMPETITORS, MARS_BRANDS,
  PREBUILT_AUDIENCES, SCENARIO_TYPE_TO_NAME, getSortedBrandList, options } from '../Blade/collapse/const';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import CustomTag from '../CustomTag/CustomTag';
import {
  collectBrandsNames,
  collectManufacturerNames,
  getBrandsByCategoryId,
  getManufacturersByCategoryId,
  isMarsManufacturer,
} from '../../utils/brand.utils';
import { EPartyDataToName } from '../Blade/collapse/OriginCollapse/const';
import {
  D2C_WEBSITE_DATA,
  MARKETING_CAMPAIGNS,
  buyerTypeToTagName,
  engagementToTagName,
} from '../Blade/collapse/AdditionalParametersCollapse/const';
import { Space, Spin } from 'antd';
import {
  useBrandMarketingCampaignsLazyQuery,
  useGetAudienceTemplateLazyQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../apollo/graphql-types';
import { IAudienceToCompare } from '../../store/audienceComparison/typings';
import { useSelector } from 'react-redux';
import { selectScenario } from '../../store/wizzard/selectors';
import { generateTabName } from '../Blade/WizardTabs/utils';
import { flatten } from 'lodash';

interface ITagsProps {
  templateId?: ITemplate['id'];
  width?: number;
  visible: boolean;
  audiences?: ( IAudienceToCompare | undefined | null | AUDIENCE_ID )[];
}

export const Tags = ( { templateId, width, visible, audiences }: ITagsProps ) => {
  const [error, setError] = useState( false );
  const [loading, setLoading] = useState( false );
  const [tags, setTags] = useState<( JSX.Element | undefined )[][]>();
  const selectedScenario = useSelector( selectScenario );

  const [loadAudiences, { loading: loadingAudiences, error: errorAudiences }] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadCompetitors, { loading: loadingCompetitors, error: errorCompetitors }] = useGetBrandsLazyQuery( {
    ...options(), variables: { isCompetitor: true },
  } );
  const [loadCampaigns, { loading: loadingCampaigns, error: errorCampaigns }] = useBrandMarketingCampaignsLazyQuery( options() );
  const [loadMarsBrands, { loading: loadingMarsBrands, error: errorMarsBrands }] = useGetBrandsLazyQuery( options() );
  const [loadTemplate, { data, loading: loadingTemplate, error: errorTemplate }] = useGetAudienceTemplateLazyQuery();

  const renderTags = async () => {
    let audiencesToTag = audiences || [];

    if ( !!templateId ) {
      audiencesToTag = [
        data?.getAudienceTemplate.audienceA,
        data?.getAudienceTemplate.audienceB,
        data?.getAudienceTemplate.audienceC,
      ].filter( ( audience ) => !!audience );
    }

    const tagsToShow = await Promise.all(
      audiencesToTag.map( async ( audience, index ) => {
        const tags = [];

        if ( typeof audience === 'string' ) {
          tags.push(
            <CustomTag
              key={`${audience}-vs`}
              tagValue={[`${generateTabName( selectedScenario, audience )}: Not Selected`]}
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
            />
          );
        } else {
          const audienceParameters = audience?.audienceParameters;
          const audiencePreBuiltAudiencesIds = audience?.preBuiltAudienceIds;
          const selectedBrands = audienceParameters?.brands || [];
          const buyerTypes = audienceParameters?.buyerTypes?.length === 5
            ? ALL_BUYER_TYPES
            : audienceParameters?.buyerTypes?.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );

          // Pre built audiences tags
          if ( !isEmpty( audiencePreBuiltAudiencesIds ) ) {
            const preBuiltAudiences = await loadAudiences();
            const preAudiencesNames = audiencePreBuiltAudiencesIds
              ?.map( ( preBuilt: number ) => preBuiltAudiences?.data?.preBuiltAudienceNames.find( ( a ) => a.id === preBuilt )?.name );

            tags.push( PREBUILT_AUDIENCES );
            preAudiencesNames && tags.push( preAudiencesNames.join( ', ' ) );

          // Competitors brands tags
          } else if ( !!audienceParameters?.manufacturers && !isMarsManufacturer( audienceParameters?.manufacturers ) ) {
            const competitorsData = await loadCompetitors();
            const competitors = competitorsData.data?.categorizedBrands;
            const competitorsBrandNames = collectBrandsNames( competitors || [] );
            const competitorsManufacturerNames = collectManufacturerNames( competitors || [] );
            const manufacturersList = getManufacturersByCategoryId( audienceParameters.category, competitors ) || [];
            const availableBrands = flatten( competitors
              ?.find( ( category ) => category.categoryId === audienceParameters?.category )
              ?.manufacturers
              .filter( ( manufacturer ) => audienceParameters?.manufacturers.includes( manufacturer.manufacturerId ) )
              .map( ( manufacturer ) => manufacturer.brands ) );
            const selectedCategoryName = competitors
              ?.find( ( category ) => audienceParameters.category === category.categoryId )?.categoryName || '';
            const isAllBrandsSelected = availableBrands?.length === selectedBrands?.length;
            const isAllManufacturersSelected = manufacturersList?.length === audienceParameters?.manufacturers?.length;
            const SOME_MANUFACTURERS_CAPTION = audienceParameters.manufacturers
              ?.map( ( manufacturer ) => competitorsManufacturerNames[manufacturer] ).join( ', ' );
            const ALL_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION} ${ALL_BRANDS}`;
            const SOME_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION}
             ${getSortedBrandList( selectedBrands, availableBrands )?.map( ( brand ) => competitorsBrandNames[brand] ).join( ', ' )}`;
            const brandTagValue = `${selectedCategoryName} 
            ${isAllBrandsSelected && availableBrands.length > 1 ? [ALL_BRANDS_CAPTION] : SOME_BRANDS_CAPTION}`;

            tags.push( COMPETITORS );
            tags.push( brandTagValue );
            tags.push( buyerTypes );

          // Mars brands tags
          } else if ( isMarsManufacturer( audienceParameters?.manufacturers ) ) {
            const selectedCategory = audienceParameters?.category || 0;
            const marsBrandsData = await loadMarsBrands();
            const marsBrands = marsBrandsData.data?.categorizedBrands;
            const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData.data?.categorizedBrands ) || [];
            const marsBrandNames = collectBrandsNames( marsBrands || [] );
            const selectedCategoryName = marsBrandsData.data?.categorizedBrands
              ?.find( ( category ) => selectedCategory === category.categoryId )?.categoryName || '';
            const brandsToShow = selectedBrands.length === availableBrands.length
              ? ALL_BRANDS
              : selectedBrands.map( ( brand ) => marsBrandNames[brand] ).join( ', ' );

            tags.push( MARS_BRANDS );
            tags.push( `${selectedCategoryName} ${brandsToShow}` );

            const originValue = !!audienceParameters?.firstParty
              ? EPartyDataToName.FIRST_PARTY_DATA
              : !!audienceParameters?.thirdParty
                ? EPartyDataToName.THIRD_PARTY_DATA
                : '';

            if ( !!originValue ) {
              tags.push( originValue );
            }

            if ( !!audienceParameters?.buyerTypes?.length ) {
              tags.push( buyerTypes );
            }

            // load and collect tags for mars campaings
            if ( !!audienceParameters?.marketingCampaigns ) {
              const brandsToLoad = selectedBrands.map( ( brandId ) => {
                const name = marsBrandNames[brandId];
                return {
                  id: brandId,
                  name,
                };
              } );

              const campaignsData = await loadCampaigns( { variables: { brands: brandsToLoad || [] } } );

              tags.push( MARKETING_CAMPAIGNS );
              tags.push( audienceParameters.marketingCampaigns
                ?.map( ( campaign ) =>
                  campaignsData.data?.brandMarketingCampaigns?.no?.find( ( item ) => item.id === campaign )?.name ).join( ', ' ) );
            }

            // collect tag for engagement
            if ( !!audienceParameters?.engagementStatus ) {
              tags.push( engagementToTagName[audienceParameters.engagementStatus] );
            }

            if ( !!audienceParameters?.d2c ) {
              tags.push( D2C_WEBSITE_DATA );
            }
          }
        }

        // add VS tag between audiences
        if ( ( audiencesToTag.length === 3 && index < 2 ) || ( audiencesToTag.length === 2 && index < 1 ) ) {
          tags.push(
            <CustomTag
              tagValue={[SCENARIO_TYPE_TO_NAME[selectedScenario]]}
              key={index}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
            />
          );
        }

        return tags.map( ( tag ) => typeof tag === 'string'
          ? <CustomTag key={tag} maxWidth={!!templateId ? ( width || 200 ) - 55 : undefined} tagValue={tag} />
          : tag
        );
      } )
    );

    return setTags( tagsToShow );
  };

  useEffect( () => {
    if ( !!templateId ) {
      loadTemplate( {
        ...options(),
        variables: { templateId },
      } );
    }
  }, [] );

  useEffect( () => {
    if ( loadingAudiences || loadingCompetitors || loadingCampaigns || loadingMarsBrands || loadingTemplate ) {
      setLoading( true );
    } else {
      setLoading( false );
    }
  }, [loadingAudiences, loadingCompetitors, loadingCampaigns, loadingMarsBrands, loadingTemplate] );

  useEffect( () => {
    if ( errorAudiences || errorCompetitors || errorCampaigns || errorMarsBrands || errorTemplate ) {
      setError( true );
    } else {
      setError( false );
    }
  }, [errorAudiences, errorCompetitors, errorCampaigns, errorMarsBrands, errorTemplate] );

  useEffect( () => {
    renderTags();
  }, [data, audiences] );

  if ( !visible ) return null;

  return (
    <div className='tooltip-tags'>
      {!error
        ? !loading ? tags : <Space className='loader' size='middle'><Spin /></Space>
        : (
          <div className='error'>Couldn't load the data. Please retry.</div>
        )}
    </div>
  );
};
