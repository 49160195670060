import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Flex, Layout, Row, Spin, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import TitleWithCaption from '../TitleWithCaption/TitleWithCaption';

import { AUDIENCE_ID, options } from '../Blade/collapse/const';
import { resetWizard, setWizardIsOpen } from '../../store/wizzard/wizardSlice';
import { selectTabs } from '../../store/tab/selectors';
import { resetTabs, setActiveTab } from '../../store/tab/tabSlice';
import { SaveTemplate } from './Header/SaveTemplate/SaveTemplate';
import { Entries } from '../../store/typings';
import { HeaderCaption } from './Header/HeaderCaption';
import { AttributesComparison } from './AttributesComparison/AttributesComparison';
import { ExportXLSX } from './Header/ExportXLSX';
import ErrorSvg from '../../assets/icons/404.svg';

import {
  selectAudiencesToCompare,
  selectAudienceComparisonIsAborting,
  selectAudienceComparisonIsLoading,
} from '../../store/audienceComparison/selectors';
import {
  resetAudienceComparison,
  setAppliedAudiencesToCompare,
  setAudienceComparisonIsAborting,
  setAudienceComparisonIsLoading,
  setAudienceComparisonStats,
  setAudiencesToCompare,
} from '../../store/audienceComparison/actions';
import { useAudienceShortName } from './AudienceShortName';
import {
  useAudienceDistributionLazyQuery,
  useInitiateAttributesCalculationsMutation,
  useInitiateAudienceDistributionCalcMutation,
  useSummaryCardsLazyQuery,
} from '../../apollo/graphql-types';

import './FilledAudienceComparison.scss';
import { sortBy } from 'lodash';
import { SummaryCard } from './SummaryCards/SummaryCard';

export const AudienceNamesContext = React.createContext( {} as Record<AUDIENCE_ID, string> );

const FilledAudienceComparison = () => {
  const [loadedQueries, setLoadedQueries] = useState<string[]>( [] );
  const [error, setError] = useState( false );
  const [retry, setRetry] = useState( false );
  const [namesUpdated, setNamesUpdated] = useState( false );

  const audienceNames = useAudienceShortName();

  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );
  const tabs = useSelector( selectTabs );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const filledAudiences = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> ).filter( ( [key, audience] ) => {
    if ( ( tabs[AUDIENCE_ID.AUDIENCE1].isFinished ? tabs[key].isFinished : true )
    && ( !isEmpty( audience?.audienceParameters ) || !isEmpty( audience?.preBuiltAudienceIds ) ) ) {
      return true;
    }

    return false;
  } ).map( ( [key, audience] ) => audience );

  const audienceComparisonIsAborting = useSelector( selectAudienceComparisonIsAborting );

  const dispatch = useDispatch();

  const onEditClick = () => {
    dispatch( setWizardIsOpen( true ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE1 ) );
  };

  const abortController = useRef( new AbortController() );
  const abortControllerSecond = useRef( new AbortController() );

  const [summaryCards, { data: summaryCardsData }] = useSummaryCardsLazyQuery();

  const [initiateComparison, {
    data: dataInitiate,
    error: initiateError,
    reset: resetInitialLoading,
  }] = useInitiateAudienceDistributionCalcMutation();

  const [audienceDistribution, { data: dataDistribution, error: distributionError }] = useAudienceDistributionLazyQuery();
  const [initiateDetailedAttributes, {
    data: dataInitiateAttributes,
    loading: initiateLoading,
    reset: resetInitiateCalculations,
  }] = useInitiateAttributesCalculationsMutation();

  const abort = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const abortSecondCalculation = () => {
    abortControllerSecond.current.abort();
    abortControllerSecond.current = new AbortController();
  };

  useEffect( () => {
    if ( audienceComparisonIsAborting ) {
      abort();
      dispatch( setAudienceComparisonIsAborting( false ) );
      dispatch( setAudienceComparisonIsLoading( false ) );

      dispatch( resetAudienceComparison() );
      dispatch( resetWizard() );
      dispatch( resetTabs() );
    }
  }, [audienceComparisonIsAborting] );

  useEffect( () => {
    if ( !namesUpdated ) {
      setNamesUpdated( true );
    }
  }, [audienceNames] );

  useEffect( () => {
    if ( !!dataInitiate
      && !!dataDistribution
      && namesUpdated
      && !!Object.values( audienceNames ).length
    ) {
      try {
        abortSecondCalculation();
        initiateDetailedAttributes( {
          variables: {
            totalsPrerequisites: {
              requestId:     dataInitiate?.initiateAudienceDistributionCalc?.requestId,
              queryId:       dataInitiate?.initiateAudienceDistributionCalc?.queryId,
              audienceNames: Object.values( audienceNames ),
            },
          },
          ...options( abortControllerSecond.current.signal ),
        } )
          .then( () => summaryCards( {
            ...options(),
            variables: {
              requestId: dataInitiate?.initiateAudienceDistributionCalc?.requestId,
            },
          } ).then( () => dispatch( setAudienceComparisonIsLoading( false ) ) ) )
          .catch( ( error ) => {
            if ( error.message !== 'The user aborted a request.' ) {
              setError( true );
            console.error(error); //eslint-disable-line
            }
          } );
      } catch ( error ) {
        console.error(error); //eslint-disable-line
      }
    }
  }, [dataInitiate, dataDistribution, namesUpdated] );

  const initialLoading = async () => {
    try {
      setLoadedQueries( [] );
      resetInitiateCalculations();
      setError( false );
      setRetry( false );
      resetInitialLoading();

      const variables: any = {
        audienceA: filledAudiences[0],
        audienceB: filledAudiences[1],
      };

      if ( filledAudiences.length === 3 ) {
        variables.audienceC = filledAudiences[2];
      }

      const initialCalcResponse = await initiateComparison( { variables, ...options( abortController.current.signal ) } );

      if ( !!initialCalcResponse.data && !isEmpty( variables ) ) {
        const audienceToCompareUpdated: any = {
          AudienceA: variables?.audienceA,
          AudienceB: variables?.audienceB,
        };

        if ( !!variables.audienceC ) {
          audienceToCompareUpdated.AudienceC = variables?.audienceC;
        }

        dispatch( setAppliedAudiencesToCompare( audienceToCompareUpdated ) );
        dispatch( setAudiencesToCompare( audienceToCompareUpdated ) );
        dispatch( resetWizard() );
        dispatch( resetTabs() );

        const { data, error } = await audienceDistribution( {
          ...options( abortController.current.signal ),
          variables: {
            totalsPrerequisites: {
              requestId:     initialCalcResponse.data.initiateAudienceDistributionCalc.requestId,
              queryId:       initialCalcResponse.data.initiateAudienceDistributionCalc?.queryId,
              audienceNames: [],
            } },
        } );

        if ( !data?.audienceDistribution ) {
          throw new Error( 'Something went wrong' );
        }

        setNamesUpdated( false );
        dispatch( setAudienceComparisonStats( data?.audienceDistribution ) );
      }
    } catch ( error ) {
      setError( true );
      console.error( error )  // eslint-disable-line
    }
  };

  useEffect( () => {
    if ( isAudienceComparisonLoading ) {
      initialLoading();
    }
  }, [isAudienceComparisonLoading, retry] );

  return (
    <AudienceNamesContext.Provider value={audienceNames}>
      <Layout className={cn( 'audience-comparison', {
        loading: isAudienceComparisonLoading,
        error:   initiateError || distributionError || error,
      } )}>
        {
          initiateError || distributionError || error
            ? (
              <>
                <img src={ErrorSvg} alt='Service Temporarily Unavailable' className='error-image'/>
                <Typography.Title level={3} >Service Temporarily Unavailable</Typography.Title>
                <p className='caption'>Service disruption detected. Please try again soon or reach out to
                  <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
                for help.</p>
                <Button type='primary' onClick={() => initialLoading()}>Retry</Button>
              </>
            )
            :isAudienceComparisonLoading
              ? <Flex vertical align='center' justify='center' className='audience-comparison-spinner'>
                <Spin />
                <span>The request might take a little longer...</span>
              </Flex>
              : <>
                <Row className='audience-comparison-header'>
                  <Col span={12}>
                    <TitleWithCaption headingLevel={1} heading='Audience Comparison' />
                  </Col>
                  <Col span={12}>
                    <Flex wrap='wrap' gap='small' justify='flex-end' className='audience-comparison-header-buttons'>
                      <Button onClick={onEditClick} icon={<EditOutlined />}>Edit Parameters</Button>
                      <ExportXLSX
                        requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId}
                        loadedQueries={loadedQueries}
                        loading={initiateLoading}
                        attributesData={dataInitiateAttributes}
                      />
                      <SaveTemplate />
                    </Flex>
                  </Col>
                  <HeaderCaption />
                </Row>
                <Row>
                  <Col span={24} className='overview'>
                    <div className='section-title'>
                      <TitleWithCaption
                        headingLevel={2}
                        heading='Overview'
                      />
                    </div>
                    {
                      !!summaryCardsData?.summaryCards.length
                    && !!dataInitiateAttributes?.initiateAttributesCalculations.only.length
                  && <Layout>
                    <Flex gap={16} className={cn( 'attribute-summary', { 'count-3': summaryCardsData.summaryCards?.length === 3 } )}>
                      {
                        sortBy( summaryCardsData?.summaryCards, 'audiencePosition' )
                          .map( ( card ) => (
                            <SummaryCard
                              dataInitiateAttributes={dataInitiateAttributes?.initiateAttributesCalculations}
                              card={card}
                              key={card.audiencePosition}
                            />
                          ) )
                      }
                    </Flex>
                  </Layout>
                    }
                  </Col>
                </Row>
                <AttributesComparison requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId || ''}
                />
              </>
        }
      </Layout>
    </AudienceNamesContext.Provider>
  );
};

export default FilledAudienceComparison;
