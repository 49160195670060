import { Tag } from 'antd';
import {
  DefaultAttributeValueRatioIndex,
  DefaultSummaryCardAttributes,
  DetailedAttributeValueRatioIndex,
  DetailedDefaultSummaryCardAttribute,
} from '../../../apollo/graphql-types';

import './DefaultAttributes.scss';
import { TextPopover } from '../../TextPopover/TextPopover';
import cn from 'classnames';
import { deatailedIcons } from './const';

interface IDefaultAttributesProps {
  defaultAttributes: DefaultSummaryCardAttributes[] | DetailedDefaultSummaryCardAttribute[];
}

interface IIndexTagProps {
  attributeIndex: DefaultAttributeValueRatioIndex;
}

const IndexTag = ( { attributeIndex }: IIndexTagProps ) => <Tag>
  <TextPopover
    text={`${attributeIndex.valueName}`}
    maxWidth={640}
    className='name'
  />
  <span className='percentage'>({attributeIndex.percentage}%</span>
  {
    attributeIndex.ratioIndex && (
      <>
        <span className={cn( 'index', {
          green: attributeIndex.ratioIndex >= 120,
          red:   attributeIndex.ratioIndex < 80,
        } )}><span className='separator'>, </span>{attributeIndex.ratioIndex}</span> index)
      </>
    )
  }
</Tag>;

export const DefaultAttributes = ( { defaultAttributes }: IDefaultAttributesProps ) => (
  <div className='default-attributes'>
    {
      defaultAttributes.map( ( attribute, index ) => (
        <div
          className='default-attributes-info'
          key={`
            ${attribute.attributeName}-${'attributeValue' in attribute ? attribute.attributeValue : attribute.maxPercentage?.valueName}
            -${index}
            `}
        >
          <div className='icon'>
            {deatailedIcons[attribute.attributeName.toLowerCase().split( ' ' ).join( '_' )]}
          </div>
          <div className='info'>
            <span>{attribute.attributeName}</span>
            <span>
              <b>{'attributeValue' in attribute
                ? `${attribute.attributeValue} (${attribute.attributePercentage}%)`
                : attribute.maxPercentage !== null
                  ? `${attribute.maxPercentage?.valueName} (${attribute.maxPercentage?.percentage}%)`
                  : 'There is no data for this attribute'
              } </b>
            </span>
            {'maxRatioIndex' in attribute && !!attribute.maxRatioIndex && (
              <IndexTag attributeIndex={attribute.maxRatioIndex} />
            )}
            {'minRatioIndex' in attribute && !!attribute.minRatioIndex && (
              <IndexTag attributeIndex={attribute.minRatioIndex} />
            )}
          </div>
        </div>
      ) )
    }
  </div>
);
