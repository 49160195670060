import { useEffect, useRef, useState } from 'react';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, InfoCircleOutlined, MergeCellsOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Form, Input, Modal, Popconfirm, Typography } from 'antd';
import useClickAway from 'react-use/lib/useClickAway';

import { options } from '../../Blade/collapse/const';
import { useDispatch } from 'react-redux';
import { Tags } from '../../Tags/Tags';
import {
  setAudienceComparisonIsLoading,
  setAudiencesToCompare,
  setAppliedAudiencesToCompare,
} from '../../../store/audienceComparison/actions';

import {
  AudienceTemplateAnnotation,
  useDeleteAudienceTemplateMutation,
  useGetAudienceTemplateLazyQuery,
  useRenameAudienceTemplateMutation,
} from '../../../apollo/graphql-types';

import './DashboardCard.scss';

export interface ITemplate {
  id: number;
  name: string;
}
interface ITemplateProps {
  template: ITemplate;
  successMessage: ( content: string ) => void;
  errorMessage: ( content?: string ) => void;
}

export const DasboardCard = ( { template, successMessage, errorMessage }: ITemplateProps ) => {
  const dispatch = useDispatch();

  const [renamed, setRenamed] = useState( '' );
  const [loadTags, setLoadTags] = useState( false );
  const [modalOpen, setModalOpen] = useState( false );
  const [okDisabled, setOkDisabled] = useState( false );
  const [inputValue, setInputValue] = useState( template.name );
  const [dropdownOpen, setDropdownOpen] = useState( false );

  const name = !!renamed ? renamed : template.name;

  const ref = useRef<HTMLDivElement | null>( null );
  useClickAway( ref, () => setDropdownOpen( false ) );

  const [form] = Form.useForm();

  const [deleteTemplate] = useDeleteAudienceTemplateMutation( {
    ...options(),
    variables:   { templateId: template.id },
    update:    ( cache, { data } ) => {
      cache.modify( {
        fields: {
          listAudienceTemplates ( currentTemplates: any ) {
            return currentTemplates.filter( ( template: any ) => template.id !== data?.deleteAudienceTemplate.id );
          },
        },
      } );
    },
  } );
  const [renameTemplate] = useRenameAudienceTemplateMutation( {
    ...options(),
    update:    ( cache, { data } ) => {
      cache.modify( {
        fields: {
          listAudienceTemplates ( currentTemplates ) {
            return currentTemplates.map( ( template: AudienceTemplateAnnotation ) => {
              if ( template.id === data?.renameAudienceTemplate.id ) {
                template.name === data?.renameAudienceTemplate.name;
              }

              return template;
            } );
          },
        },
      } );
    },
  } );

  const [retrieveTemplate] = useGetAudienceTemplateLazyQuery( {
    ...options(),
    variables:   { templateId: template.id },
  } );

  const confirmDelete = () => {
    const deleteTemplateConfirm = async () => {
      try {
        const response = await deleteTemplate();
        setDropdownOpen( false );

        if ( !!response.errors ) {
          console.error( response.errors ); //eslint-disable-line
          errorMessage( 'Deletion unsuccessful, please try again later.' );
          return;
        }

        successMessage( 'Template was successfully deleted' );

      } catch ( error ) {
        errorMessage( 'Deletion unsuccessful, please try again later.' );
        setDropdownOpen( false );
      }
    };

    deleteTemplateConfirm();
  };

  const items = [
    {
      key:   '1',
      label: (
        <div className='popover-button' onClick={() => {
          setModalOpen( true );
          setDropdownOpen( false );
        }}><EditOutlined /> Rename</div>
      ),
    },
    {
      key:   '2',
      label: (
        <Popconfirm
          placement='left'
          title={'Are you sure you want to delete this template?'}
          okText='Yes'
          cancelText='No'
          okButtonProps={{ loading: false }}
          onConfirm={confirmDelete}
          onCancel={() => setDropdownOpen( false )}
          overlayStyle={{ width: '224px' }}
          style={{ marginRight: '30px' }}
          getPopupContainer={( node ) => node.parentElement!}
        >
          <div className='popover-button delete'>
            <DeleteOutlined /> Delete
          </div>
        </Popconfirm>
      ),
    },
  ];

  const handleDiscover = () => {
    const discover = async () => {
      try {

        const retrievedTemplate = await retrieveTemplate();

        const audiences = retrievedTemplate.data?.getAudienceTemplate;

        if ( !!retrievedTemplate.error ) {
          errorMessage( 'Something went wrong. Couldn\'t load the data. Please try again.' );
        }

        if ( !audiences ) return false;

        const audiencesToCompare: any = {
          AudienceA: audiences.audienceA,
          AudienceB: audiences.audienceB,
        };

        if ( !!audiences.audienceC ) {
          audiencesToCompare.AudienceC = audiences.audienceC;
        }

        dispatch( setAudiencesToCompare( audiencesToCompare ) );
        dispatch( setAppliedAudiencesToCompare( audiencesToCompare ) );
        dispatch( setAudienceComparisonIsLoading( true ) );

      } catch ( error ) {
        errorMessage( 'Something went wrong. Couldn\'t load the data. Please try again.' );
        console.error( new Error( `Loading data error: ${error}` ) ); // eslint-disable-line
      }
    };

    discover();
  };

  const handleRename = () => {
    const renameTemplateClick = async () => {
      try {
        const response = await renameTemplate( { variables: {
          audienceTemplateAnnotation: {
            id:   template.id,
            name: inputValue,
          },
        } } );

        setDropdownOpen( false );
        setModalOpen( false );

        if ( !!response.errors ) {
          console.error( response.errors ); //eslint-disable-line
          errorMessage( 'Rename failed, please try again later.' );
          return;
        }

        setRenamed( inputValue );
        successMessage( 'Template was successfully renamed' );
      } catch ( error ) {
        errorMessage( 'Rename failed, please try again later.' );
      }
    };

    renameTemplateClick();
  };

  useEffect( () => {
    if ( inputValue === name || inputValue.length === 0 ) {
      setOkDisabled( true );
    } else {
      setOkDisabled( false );
    }
  }, [inputValue, template] );

  return (
    <Card className='dasboard-card' ref={ref} key={template.id}>
      <div className='dasboard-card-header'>
        <MergeCellsOutlined className='main-icon'/>

        <div className='template-actions'>
          <div className='tags' onMouseOver={() => setLoadTags( true )} onMouseLeave={() => setLoadTags( false )} >
            <InfoCircleOutlined />
            {loadTags && <Tags templateId={template.id} width={ref.current?.offsetWidth} visible={loadTags}/>}
          </div>

          <Dropdown
            menu={{ items }}
            trigger={['click' ]}
            placement='bottomRight'
            open={dropdownOpen}
            getPopupContainer={( node ) => node.parentElement?.parentElement!}
          >
            <EllipsisOutlined onClick={() => setDropdownOpen( dropdownOpen ? false : true ) }/>
          </Dropdown>

          <Modal
            destroyOnClose
            centered
            maskClosable={false}
            okText='Rename Template'
            title='Rename Template'
            open={modalOpen}
            onOk={handleRename}
            okButtonProps={{
              disabled: okDisabled,
            }}
            onCancel={() => setModalOpen( false )}
            className='dashboard_card-modal'
          >
            <Form layout='vertical' form={form} >
              <Form.Item label='Template Name'>
                <Input
                  showCount
                  maxLength={150}
                  placeholder='Template Name'
                  value={inputValue}
                  onChange={( e ) => setInputValue( e.target.value.trimStart() )}
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
      <div className='dasboard-card-content'>
        <Typography.Title level={5}>
          {renamed || template.name}
        </Typography.Title>
      </div>
      <Button onClick={handleDiscover}>Discover</Button>
    </Card>
  );
};
