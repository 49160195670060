import { EngagementStatus } from '../../../../apollo/graphql-types';
import { BuyerType } from '../../../../typings';

export const buyerTypeToTagName = {
  [BuyerType.NonBuyer]: 'Non-Buyer',
  [BuyerType.Lapsed]:   'Lapsed',
  [BuyerType.Light]:    'Light',
  [BuyerType.Medium]:   'Medium',
  [BuyerType.Heavy]:    'Heavy',
};

export const engagementToTagName = {
  [EngagementStatus.Clicked]: 'Clicked',
  [EngagementStatus.Opened]:  'Opened',
  [EngagementStatus.Reached]: 'Reached',
};

export const MARKETING_CAMPAIGNS = 'Marketing Campaigns';
export const D2C_WEBSITE_DATA = 'D2C Website Data';

export const ENGAGEMENT = 'Engagement';

export const engagementOptions = [
  { label: engagementToTagName[EngagementStatus.Reached], value: EngagementStatus.Reached },
  { label: engagementToTagName[EngagementStatus.Opened], value: EngagementStatus.Opened },
  { label: engagementToTagName[EngagementStatus.Clicked], value: EngagementStatus.Clicked },
];
