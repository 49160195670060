import { EScenario } from '../../../store/wizzard/wizardSlice';
import { AUDIENCE_ID, TAB_HEADERS } from '../collapse/const';

export const generateTabName = ( scenario: EScenario, name: AUDIENCE_ID ) => {
  if ( scenario === EScenario.compare ) {
    return `${TAB_HEADERS[name]} ${name === AUDIENCE_ID.AUDIENCE1 ? '(Benchmark)' : '(Comparison)'}`;
  }

  return TAB_HEADERS[name];
};
