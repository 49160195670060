import { useEffect } from 'react';
import { Dropdown, MenuProps, Tabs } from 'antd';
import { CheckOutlined, ClearOutlined,
  CopyOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setSpecificTab } from '../../../store/tab/tabSlice';
import { duplicateWizzardById, EScenario, resetAllWizards } from '../../../store/wizzard/wizardSlice';
import BladeContent from '../bladeCommon/BladeContent';
import { AUDIENCE_ID } from '../collapse/const';
import { selectIsWizardOpen, selectScenario, selectWizardSourceByTabName } from '../../../store/wizzard/selectors';
import { selectAudiencesToCompare, selectHasAudiencesToCompare } from '../../../store/audienceComparison/selectors';
import { deleteAudience, duplicateAudienceById, setAudienceParameters } from '../../../store/audienceComparison/actions';

import './WizardTabs.scss';
import { selectTabs, selectTabsActiveTab } from '../../../store/tab/selectors';
import { generateTabName } from './utils';
import { AddAudience } from './AddAudience';
import cn from 'classnames';

const WizardTabs: React.FC = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector( selectTabsActiveTab );
  const sourceWizard = useSelector( selectWizardSourceByTabName( activeTab ) );
  const tabs = useSelector( selectTabs );
  const isWizardOpen = useSelector( selectIsWizardOpen );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const selectedScenario = useSelector( selectScenario );

  const handleTabChange = ( tabName: string ) => {
    dispatch( setActiveTab( tabName === 'add_audience' ? AUDIENCE_ID.AUDIENCE3 : tabName ) );
  };

  const handleClearTab = () => {
    dispatch( resetAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, undefined] ) );
  };

  const handleDelete2nd = () => {
    dispatch( deleteAudience( activeTab ) );
    dispatch( resetAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, reset: true } ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE2 ) );
  };

  const handleDuplicate = ( audienceId: AUDIENCE_ID ) => {
    dispatch( duplicateAudienceById( audienceId ) );
    dispatch( duplicateWizzardById( audienceId ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE3 ) );
    dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.AUDIENCE3, isFinished: true } ) );
  };

  const generateItems: ( audienceId: AUDIENCE_ID ) => MenuProps['items'] = ( audienceId: AUDIENCE_ID ) => [
    {
      key:      'reset',
      disabled: !sourceWizard.isFinished,
      icon:     <ClearOutlined />,
      label:    'Reset parameters',
      onClick:  handleClearTab,
    },
    {
      key:      'duplicate',
      icon:     <CopyOutlined />,
      label:    'Duplicate',
      disabled: !tabs[activeTab].isFinished || Object.keys( audiencesToCompare ).length === 3,
      onClick:  () => handleDuplicate( audienceId ),
    },
    {
      key:     'delete',
      danger:  true,
      icon:    <DeleteOutlined />,
      label:   activeTab === AUDIENCE_ID.AUDIENCE3 ? 'Delete': null,
      style:   { display: activeTab === AUDIENCE_ID.AUDIENCE3 ? 'inherit' : 'none' },
      onClick: handleDelete2nd,
    },
  ];

  const generateMenuContent = ( audienceId: AUDIENCE_ID ) => (
    <Dropdown
      trigger={['click']}
      menu={{ items: generateItems( audienceId ) }}
      placement='bottomRight'
      rootClassName='custom-styled-dropdown'
    >
      <EllipsisOutlined style={{ margin: '0 0 0 8px' }} />
    </Dropdown>
  );

  const generateTabItems = () => {
    const items = Object.entries( audiencesToCompare ).map( ( [key, audience] ) => ( {
      key,
      forceRender: true,
      label:       (
        <>
          {generateTabName( selectedScenario, key as AUDIENCE_ID )}
          {generateMenuContent( key as AUDIENCE_ID )}
        </>
      ),
      icon:        tabs[key as AUDIENCE_ID]?.isFinished && <CheckOutlined />,
      children:    <BladeContent audience={key as AUDIENCE_ID} />,
      disabled:    false,
    } ) );

    if ( items.length < 3 && selectedScenario !== EScenario.single ) {
      items.push( {
        forceRender: true,
        disabled:    true,
        key:         'add_audience',
        label:       <AddAudience />,
        icon:        false,
        children:    <></>,
      } );
    }

    return items;
  };

  useEffect( () => {
    if ( !tabs[activeTab]?.isFinished ) {
      dispatch( setSpecificTab( { activeTab } ) );
    }
  }, [tabs[activeTab]?.isFinished] );

  useEffect( () => {
    const hasSecondComparison = !!audiencesToCompare[AUDIENCE_ID.AUDIENCE3]?.audienceParameters
		|| !!audiencesToCompare[AUDIENCE_ID.AUDIENCE3]?.preBuiltAudienceIds;
    if ( isWizardOpen && hasSecondComparison && !tabs[AUDIENCE_ID.AUDIENCE3]?.isVisible ) {
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.AUDIENCE3, isVisible: true } ) );
    }

    if ( isWizardOpen && hasAudiencesToCompare ) {
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.AUDIENCE1, isFinished: true } ) );
      dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.AUDIENCE2, isFinished: true } ) );

      if ( hasSecondComparison ) {
        dispatch( setSpecificTab( { activeTab: AUDIENCE_ID.AUDIENCE3, isFinished: true } ) );
      }
    }
  }, [isWizardOpen, hasAudiencesToCompare] );

  return (
    <Tabs
      rootClassName={cn( 'wizzard-tabs', { 'short': Object.keys( audiencesToCompare ).length } )}
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      items={generateTabItems()}
      onChange={handleTabChange}
    />
  );
};

export default WizardTabs;
