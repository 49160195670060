import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Flex } from 'antd';

import './ErrorRetryCircle.scss';

interface IErrorRetryCircleProps {
  title: string;
  description: JSX.Element | string;
  onClick: () => void;
}

export const ErrorRetryCircle = ( { onClick, title, description }: IErrorRetryCircleProps ) => <Flex className='error-retry-circle'>
  <CloseCircleFilled />
  <p className='error-title'>{title}</p>
  <p className='text'>{description}</p>
  <Button type='primary' onClick={onClick}>Retry</Button>
</Flex>;
