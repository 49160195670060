import CollapseWizard from '../CollapseWizard/CollapseWizard';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AUDIENCE_ID } from '../collapse/const';

interface IBladeContentProps {
  audience: AUDIENCE_ID;
}

const BladeContent = ( { audience }: IBladeContentProps ) => (
  <div className='parameters-wizard'>
    <TitleWithCaption
      heading='Parameters Wizard'
      caption='Choose parameters one by one to get what are you looking for'
      captionFontSize={12}
    />
    <CollapseWizard audience={audience}/>
  </div>
);

export default BladeContent;
