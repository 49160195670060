import { createSlice } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { omit } from 'lodash';

export interface ITabSlice {
  activeTab: AUDIENCE_ID;
  tabsList: Record<AUDIENCE_ID, ITabItem>;
}

export interface ITabItem {
  isFinished: boolean;
  isVisible: boolean;
}

const initialState: ITabSlice = {
  activeTab: AUDIENCE_ID.AUDIENCE1,
  tabsList:  {
    [AUDIENCE_ID.AUDIENCE1]:         {} as ITabItem,
    [AUDIENCE_ID.AUDIENCE2]:         {} as ITabItem,
    [AUDIENCE_ID.AUDIENCE3]: {} as ITabItem,
  },
};

export const tabSlice = createSlice( {
  name:     'tabs',
  initialState,
  reducers: {
    resetTabs: ( state ) => {
      state = initialState;
      return state;
    },
    setActiveTab: ( state, action ) => {
      state.activeTab = action.payload;
    },
    setSpecificTab: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      const reset = action.payload.reset;

      if ( reset ) {
        state.tabsList[activeTab] = { ...initialState.tabsList.AudienceA };
        return state;
      }

      state.tabsList[activeTab] = { ...state.tabsList[activeTab], ...omit( action.payload, 'activeTab' ) };
    },
  },
} );

export const { setActiveTab, setSpecificTab, resetTabs } = tabSlice.actions;
export default tabSlice.reducer;
