import { createSelector } from 'reselect';
import { RootState } from '../store';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { WIZARD_NAME } from '../../components/Blade/types';

export const selectWizards = ( state: RootState ) => state?.wizard.wizards;
export const selectIsWizardOpen = ( state: RootState ) => state?.wizard.isOpen;
export const selectScenario = ( state: RootState ) => state?.wizard.scenario;
export const selectWizardPrebuiltAudienceByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].prebuiltAudience
);

export const selectWizardSourceByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].source
);
export const selectWizardAdditionalParametersByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].additionalParameters
);
export const selectWizardMarsBrandsByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].marsBrands
);
export const selectWizardOriginByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].origin
);
export const selectWizardCompetitorsByTabName = ( tabName: AUDIENCE_ID ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName].competitors
);

export const selectWizardStepByTabAndStepName = ( tabName: AUDIENCE_ID, stepName: WIZARD_NAME ) => createSelector(
  selectWizards,
  ( wizard ) => wizard[tabName][stepName]
);
