import { CheckOutlined } from '@ant-design/icons';
import { Button, Divider, Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import { ENGAGEMENT, engagementOptions } from './const';
import { AUDIENCE_ID } from '../const';
import { MarketingCampaign } from '../../../../apollo/graphql-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCampaignsByAudienceId,
  selectEngagementByAudienceId } from '../../../../store/audienceComparison/selectors';
import { setAdditionalParametersWizard } from '../../../../store/wizzard/wizardSlice';
import { selectWizardAdditionalParametersByTabName } from '../../../../store/wizzard/selectors';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import cn from 'classnames';

interface IMarketingCampaingsContentProps {
  activeTab: AUDIENCE_ID;
  marketingCampaignsList: MarketingCampaign[] | null | undefined;
}

export const MarketingCampaingsContent = ( { activeTab, marketingCampaignsList }: IMarketingCampaingsContentProps ) => {
  const dispatch = useDispatch();
  const selectedCampaigns = useSelector( selectCampaignsByAudienceId( activeTab ) );
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( activeTab ) );
  const selectedEngagement = useSelector( selectEngagementByAudienceId( activeTab ) );

  const isEngagement = !!selectedCampaigns?.filter( ( campaignId ) =>
    marketingCampaignsList?.find( ( c ) =>
      c.id === campaignId )?.isEmail
  )?.length;

  const [showAll, setShowAll] = useState( false );

  const handleMarketingCampaignsButtonClick = ( id: number ) => {
    dispatch(
      setAdditionalParametersWizard( {
        ...additionalParametersWizard,
        activeTab,
        isFinished: false,
      } ),
    );

    let selectedCampaignsList: number[];
    if ( selectedCampaigns?.includes( id ) ) {
      selectedCampaignsList = selectedCampaigns.filter( ( item ) => item !== id );
    } else {
      selectedCampaignsList = [...( selectedCampaigns || [] ), id];
    }

    dispatch( setAudienceParameters( [activeTab, { marketingCampaigns: selectedCampaignsList }] ) );
  };

  const handleEngagementRadioGroupChange = ( e: RadioChangeEvent ) => {
    dispatch( setAdditionalParametersWizard( { isFinished: false, activeTab } ) );
    dispatch( setAudienceParameters( [activeTab, { engagementStatus: e.target.value }] ) );
  };

  return <>
    <Divider style={{ margin: '1rem 0 1rem' }} />
    <div style={{ paddingBottom: 16 }}>
      <TitleWithCaption caption='Marketing Campaign' captionFontSize={16} />
    </div>
    <Space className='blade-buttons' wrap>
      {marketingCampaignsList?.slice( 0, showAll ? marketingCampaignsList.length : 10 )?.map( ( campaign ) => (
        <Button
          key={campaign.name}
          className={cn( { selected: selectedCampaigns?.includes( campaign.id ) } )}
          icon={selectedCampaigns?.includes( campaign.id ) ? <CheckOutlined /> : ''}
          onClick={() => handleMarketingCampaignsButtonClick( campaign.id )}
        >
          {campaign.name}
        </Button>
      ) )}
      {!showAll && ( marketingCampaignsList?.length || 0 ) > 10 && (
        <div className='link-button'>
          <Button type='link' onClick={() => setShowAll( true )}>
          Show All
          </Button>
        </div>
      )}
    </Space>
    {selectedCampaigns !== undefined && isEngagement && (
      <>
        <Divider style={{ margin: '1rem 0 1rem' }} />
        <div style={{ paddingBottom: 16 }}>
          <TitleWithCaption caption={ENGAGEMENT} captionFontSize={16} />
        </div>
        <Radio.Group
          options={engagementOptions}
          optionType='button'
          buttonStyle='solid'
          value={selectedEngagement}
          style={{ width: '100%' }}
          onChange={handleEngagementRadioGroupChange}
        />
        <div className='note-caption'>
          <TitleWithCaption
            caption='*Engagement is available only for Email Campaigns'
            captionFontSize={12}
          />
        </div>
      </>
    )}
  </>;
};
