import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CheckboxOptionType, Collapse, Divider, Popover, Radio, RadioChangeEvent, Space } from 'antd';
import { CheckOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import { setSpecificTab } from '../../../../store/tab/tabSlice';
import { collapseAllWizards, setAdditionalParametersWizard, setOriginWizard } from '../../../../store/wizzard/wizardSlice';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader, { ITag } from '../CollapseHeader';
import { ALL_BUYER_TYPES, AUDIENCE_ID, BUYER_TYPE_DESCRIPTION, BUYER_TYPES, options, tooltipContent } from '../const';
import { ICollapseProps } from '../types';
import {
  buyerTypeToTagName,
  ENGAGEMENT,
  engagementToTagName,
} from './const';
import { selectWizardAdditionalParametersByTabName } from '../../../../store/wizzard/selectors';
import cn from 'classnames';
import './AdditionalParametersCollapse.scss';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectBuyerTypesByAudienceId,
  selectCampaignsByAudienceId,
  selectCategoryByAudienceId,
  selectD2CByAudienceId,
  selectEngagementByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectOriginByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import {
  EngagementStatus,
  useBrandMarketingCampaignsLazyQuery,
  useGetBrandsQuery,
} from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { getBrandsByCategoryId, isMarsManufacturer } from '../../../../utils/brand.utils';
import { handleScrollIntoView } from '../utils';
import { MarketingCampaingsContent } from './MarketingCampaingsContent';
import { sortBy } from 'lodash';
import { BuyerType } from '../../../../typings';
import { EPartyData } from '../OriginCollapse/const';

interface IAdditionalParametersProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

enum EAdditionalType {
  buyerTypes = 'Buyer Types',
  marketingCampaigns = 'Marketing Campaigns',
  d2c = 'D2C Website Data',
}

const AdditionalParametersCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IAdditionalParametersProps ) => {
  const dispatch = useDispatch();
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedOrigin = useSelector( selectOriginByAudienceId( activeTab ) );

  const { data: marsBrandsData, loading: isMarsBrandsLoading, error: errorMarsBrands, refetch: refetchMarsBrands } = useGetBrandsQuery(
    {
      ...options(),
      notifyOnNetworkStatusChange: true,
    }
  );
  const [loadCampaigns, {
    data: campaignsData,
    loading: isCampaignsLoading,
    error: errorCampaigns,
    refetch: refetchCampaigns,
  }] = useBrandMarketingCampaignsLazyQuery( { ...options(), notifyOnNetworkStatusChange: true } );

  useEffect( () => {
    const errorMessage = errorCampaigns?.message ?? errorMarsBrands?.message;

    if ( !!errorMessage ) {
      console.error(`Loading data error: ${errorMessage}`); //eslint-disable-line
    }
  }, [errorCampaigns, errorMarsBrands] );

  const hasD2C = !!campaignsData?.brandMarketingCampaigns.yes;
  const hasMarketingCampaigns = !!campaignsData?.brandMarketingCampaigns.no;
  const isSelectedOnlyD2C = campaignsData?.brandMarketingCampaigns.yes
  && selectedBrands.length === campaignsData.brandMarketingCampaigns.yes.length;

  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );

  const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData?.categorizedBrands || [] );
  const selectedBuyerTypesSelector = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBuyerTypes = selectedBuyerTypesSelector || [];

  const selectedD2C = useSelector( selectD2CByAudienceId( activeTab ) );
  const selectedCampaigns = useSelector( selectCampaignsByAudienceId( activeTab ) );
  const selectedEngagement = useSelector( selectEngagementByAudienceId( activeTab ) );
  const marketingCampaignsList = campaignsData?.brandMarketingCampaigns.no;
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const isAllTypesSelected = selectedBuyerTypes.length === Object.values( BuyerType ).length;

  const [selectedType, setSelectedType] = useState( !hasMarketingCampaigns && !hasD2C ? BUYER_TYPES : '' );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  const isEngagement = !!selectedCampaigns?.filter( ( campaignId ) =>
    marketingCampaignsList?.find( ( c ) =>
      c.id === campaignId )?.isEmail
  )?.length;

  let tagValue: ITag[] = [
    { name: 'Collected Through', value: EAdditionalType.marketingCampaigns },
    {
      name:  'Campaigns',
      value: selectedCampaigns?.map( ( campaign ) => marketingCampaignsList?.find( ( item ) => item.id === campaign )?.name || '' ) || '',
    },
  ];

  if ( isEngagement && !!selectedEngagement ) {
    tagValue.push( { name: ENGAGEMENT, value: engagementToTagName[selectedEngagement] } );
  }

  if ( selectedD2C ) {
    tagValue = [{ name: TAG_VALUES.COLLECTED_THROUGH, value: EAdditionalType.d2c } ];
  }

  if ( !!selectedBuyerTypes.length ) {
    tagValue = [ {
      name:  TAG_VALUES.BUYER_TYPE,
      value: isAllTypesSelected ? ALL_BUYER_TYPES : selectedBuyerTypes.map( ( bt ) => buyerTypeToTagName[bt] ),
    } ];
  }

  useEffect( () => {
    loadCampaigns( {
      variables: {
        brands: sortBy( availableBrands
          ?.filter( ( brand ) => selectedBrands.includes( brand.brandId ) )
          .map( ( brand ) => ( { id: brand.brandId, name: brand.brandName } ) ), 'id' ),
      },
    } );
  }, [marsBrandsData] );

  const handleAdditionalParametersRadioGroupChange = ( e: RadioChangeEvent ) => {
    setSelectedType( e.target.value );
    handleScrollIntoView( scrollRef );
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: false,
      } ),
    );

    const parameters = {
      marketingCampaigns: e.target.value === EAdditionalType.marketingCampaigns ? ( selectedCampaigns || [] ) : undefined,
      engagementStatus:   e.target.value === EAdditionalType.marketingCampaigns ? selectedEngagement : undefined,
      d2c:                e.target.value === EAdditionalType.d2c ? true : undefined,
      buyerTypes:         e.target.value === EAdditionalType.buyerTypes ? selectedBuyerTypes : undefined,
    };

    dispatch( setAudienceParameters( [activeTab, parameters] ) );
  };

  const handleBuyerTypeButtonClick = ( name: BuyerType ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBuyerTypes?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: selectedBuyerTypes.filter( ( buyerType ) => buyerType !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { buyerTypes: [...selectedBuyerTypes, name] }] ) );
  };

  const handleClickAllTypes = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    isAllTypesSelected
      ? dispatch( setAudienceParameters( [activeTab, { buyerTypes: [] }] ) )
      : dispatch( setAudienceParameters( [activeTab, { buyerTypes: Object.values( BuyerType ) }] ) );
  };

  const handleFinishWizardClick = ( parameter: string ) => {
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );

    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  useEffect( () => {
    if ( !!marketingCampaignsList && isEngagement && !selectedEngagement && !isEqualComparisons ) {
      dispatch( setAudienceParameters( [activeTab, { engagementStatus: EngagementStatus.Reached }] ) );
    }

    if ( !!marketingCampaignsList && !isEngagement && !!selectedEngagement && !isEqualComparisons ) {
      dispatch( setAudienceParameters( [activeTab, { engagementStatus: undefined }] ) );
    }
  }, [isEngagement] );

  useEffect( () => {
    if ( !additionalParametersWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [additionalParametersWizard.isFinished] );

  useEffect( () => {
    if ( !!selectedCampaigns || !!selectedEngagement || !!selectedBuyerTypes ) {
      handleScrollIntoView( scrollRef );
    }
  }, [selectedCampaigns, selectedEngagement, selectedBuyerTypes] );

  useEffect( () => {
    handleScrollIntoView( scrollRef );

    const prepareCollapse = () => {
      const audience = audiencesToCompare[activeTab];

      const isMarsBrandsSource = isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isMarsBrandsSource && ( !!audience.audienceParameters?.d2c || !!audience.audienceParameters?.marketingCampaigns ) ) {
        dispatch(
          setAdditionalParametersWizard( {
            activeTab,
            isFinished: true,
            isExpand:   false,
          } ),
        );
        dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
      }
    };

    if ( hasAudiencesToCompare && isEqualComparisons ) {
      prepareCollapse();
    }
  }, [] );


  const generateOptions = () => {
    const options = [
      { label: EAdditionalType.buyerTypes, value: EAdditionalType.buyerTypes },
    ] as CheckboxOptionType[];

    if ( hasMarketingCampaigns ) {
      options.push( { label: EAdditionalType.marketingCampaigns, value: EAdditionalType.marketingCampaigns } );
    }

    if ( hasD2C ) {
      options.push( { label: EAdditionalType.d2c, value: EAdditionalType.d2c } );
    }

    return options;
  };

  const collapseItems = [
    {
      key:      WIZARD_NAME.ADDITIONAL_PARAMETERS,
      label:    <CollapseHeader
        step={WIZARD_NAME.ADDITIONAL_PARAMETERS}
        heading={`Step 04: ${STEP_NAMES.ADDITIONAL_PARAMETERS}`}
        caption='Select additional parameters for a selected brand'
        tags={tagValue}
      />
      ,
      children: <div className='additional-parameters'>
        {( hasMarketingCampaigns || hasD2C ) && selectedOrigin === EPartyData.firstParty && (
          <div className='type-radio-group'>
            <Radio.Group
              options={generateOptions()}
              className={cn( {
                'three-wide-button-group': hasMarketingCampaigns && hasD2C,
                'two-wide-button-group':   !hasMarketingCampaigns || !hasD2C,
              } )}
              optionType='button'
              buttonStyle='solid'
              value={selectedType}
              onChange={handleAdditionalParametersRadioGroupChange}
            />
            {!isSelectedOnlyD2C && selectedType === EAdditionalType.d2c && (
              <div className='note-caption'>
                <TitleWithCaption
                  caption='*D2C Website Data is available only for M&MS, Ethel M'
                  captionFontSize={12}
                />
              </div>
            )}
          </div>
        )}
        {hasMarketingCampaigns && selectedType === EAdditionalType.marketingCampaigns && (
          <>
            <MarketingCampaingsContent
              activeTab={activeTab}
              marketingCampaignsList={marketingCampaignsList}

            />
          </>
        )}

        {selectedType === EAdditionalType.buyerTypes && (
          <>
            {selectedOrigin === EPartyData.firstParty && (
              <Divider style={{ margin: '1rem 0 1rem' }} />
            )}
            <div style={{ paddingBottom: 16 }}>
              <span className='buyer_type-info'>Buyer Type</span>
              <Popover content={tooltipContent( BUYER_TYPE_DESCRIPTION )} trigger='hover' placement='bottom'>
                <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', paddingLeft: 8, cursor: 'pointer' }} />
              </Popover>
            </div>
            <Space className='blade-buttons'>
              <Button
                key='All Buyer Types'
                type={isAllTypesSelected ? 'primary' : 'default'}
                style={{ width: 621 }}
                onClick={handleClickAllTypes}
              >
                  All Buyer Types
              </Button>
            </Space>
            <Space className='blade-buttons' wrap style={{ paddingTop: 16, width: '-webkit-fill-available' }}>
              {Object.values( BuyerType ).map( ( buyerType ) => {
                const isSelected = selectedBuyerTypes.includes( buyerType );

                return (
                  <Button
                    key={buyerType}
                    className={cn( { selected: isSelected } )}
                    icon={isSelected ? <CheckOutlined /> : ''}
                    onClick={() => handleBuyerTypeButtonClick( buyerType )}
                  >
                    {buyerTypeToTagName[buyerType]}
                  </Button>
                );
              } )}
            </Space>
          </>
        )}
        <div ref={scrollRef}>
          {( !!selectedD2C || !!selectedCampaigns?.length || !!selectedBuyerTypes.length ) && (
            <>
              <Divider style={{ margin: '1rem 0 1rem' }} />
              <Space className='next-button'>
                <Button onClick={() => handleFinishWizardClick( selectedType )}>Finish Wizard</Button>
              </Space>
            </>
          )}
        </div>
      </div>,
    },
  ];

  return (
    <Collapse
      items={collapseItems}
      defaultActiveKey={WIZARD_NAME.ADDITIONAL_PARAMETERS}
      activeKey={additionalParametersWizard.isExpand ? WIZARD_NAME.ADDITIONAL_PARAMETERS : ''}
      collapsible='icon'
      expandIconPosition='end'
      onChange={() => handleCollapseChange( setAdditionalParametersWizard, additionalParametersWizard )}
      expandIcon={() =>
        additionalParametersWizard.isExpand
          ? (
            <MinusOutlined style={expandIconStyles} />
          )
          : (
            <PlusOutlined style={expandIconStyles} />
          )
      }
    />
  );
};

export default AdditionalParametersCollapse;
