import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Flex, Radio, Row, Spin, Switch, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import { Filter } from './Filter/Filter';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';

import { options } from '../../Blade/collapse/const';

import { getDefaultExpandedAndCheckedKeys } from './Filter/utils';
import { AttributeContent } from './AttributeContent/AttributeContent';
import { useAudienceShortName } from '../AudienceShortName';
import { selectAudienceComparisonIsLoading } from '../../../store/audienceComparison/selectors';
import {
  useBarchartsQuery,
  useFilteringAttributesQuery,
} from '../../../apollo/graphql-types';
import { ErrorRetryCircle } from '../../Error/ErrorRetryCircle';

import './AttributesComparison.scss';
import { AppstoreOutlined, BorderOutlined } from '@ant-design/icons';
import { IndexDescription } from '../SummaryCardsDetails/IndexDescription';
import { AudienceNamesContext } from '../FilledAudienceComparison';

interface IAttributesComparisonProps {
  requestId: string;
}

export enum EView {
  list = 'list',
  grid = 'grid'
}

export const IndexContext = React.createContext( false );
export const AttributesComparison = ( { requestId }: IAttributesComparisonProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const [showIndexes, setShowIndexes] = useState( false );
  const [view, setView] = useState<EView>( EView.list );

  const audienceComparisonIsLoading = useSelector( selectAudienceComparisonIsLoading );
  const { data: attributesData } = useFilteringAttributesQuery( options() );
  const defaultExpandedAndCheckedKeys = useMemo(
    () => getDefaultExpandedAndCheckedKeys( attributesData?.filteringAttributes ), [attributesData]
  );
  const [attributesToShow, setAttributesToShow] = useState<string[]>( [] );

  const { data: barchartsData, loading, error, refetch } = useBarchartsQuery( {
    ...options(),
    notifyOnNetworkStatusChange: true,
    variables:                   {
      barchartPrerequisites: {
        requestId,
        attributeNames: null,
      },
    },
  } );

  useEffect( () => {
    if ( !!flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ).length ) {
      setAttributesToShow( flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ) );
    }
  }, [defaultExpandedAndCheckedKeys] );

  return <>
    <Row className='section-title'>
      <Col span={10}>
        <Flex align='center'>
          <TitleWithCaption headingLevel={2} heading='Benchmark Comparison' />
        </Flex>
      </Col>
      <Col span={14}>
        <Flex justify='flex-end' align='center'>
          {!error && !loading && (
            <>
              <div className='show-indexes'>
                <Switch size='small' onChange={( value ) => setShowIndexes( value )} /> Show Indexes <IndexDescription />
              </div>

              <Radio.Group className='view-switcher' value={view} onChange={( e ) => setView( e.target.value )}>
                <Radio.Button value={EView.list}><BorderOutlined /></Radio.Button>
                <Radio.Button value={EView.grid}><AppstoreOutlined /></Radio.Button>
              </Radio.Group>
              <Filter setAttributesToShow={setAttributesToShow} />
            </>
          )}
        </Flex>
      </Col>
    </Row>

    {
      error
        ? (
          <ErrorRetryCircle
            title='Error'
            description={'Something went wrong. Couldn\'t load the data. Please try again.'}
            onClick={() => {
              refetch();
            }}
          />
        )
        : loading || Object.values( audienceNames ).includes( 'loading' )
          ? (
            <Flex
              vertical
              align='center'
              justify='center'
              className={cn( 'attributes-comparison-spinner' )}
            >
              <Spin />
              <span>The request might take a little longer...</span>
            </Flex>
          )
          : (
            <div className='attributes-comparison-content'>
              <IndexContext.Provider value={showIndexes}>

                <Tabs
                  type='card'
                  items={barchartsData?.barcharts.map( ( category ) => {
                    const attributes = category.attributes?.filter( ( attribute ) =>!!attribute
                  && attributesToShow.includes( attribute.attributeName )
                    ) || [];

                    const attributeGroups = category.groups
                      ?.map( ( attributeMap ) => {
                        const attributesList = attributeMap.attributes
                          .filter( ( attribute ) => attributesToShow.includes( attribute.attributeName ) );

                        return { ...attributeMap, attributes: attributesList };
                      } )
                      .filter( ( attribute ) => !!attribute.attributes.length ) || [];

                    if ( !attributes.length && !attributeGroups.length ) {
                      return null;
                    }

                    const label = category.categoryName;
                    const children = <React.Fragment key={category.categoryName}>
                      <Row>
                        {[...attributes, ...attributeGroups]
                          .map( ( attribute ) =>
                            (
                              <AttributeContent
                                attribute={attribute}
                                key={'groupName' in attribute ? attribute.groupName : attribute.attributeName}
                                view={view}
                                itemsCount={( category.attributes?.length || 0 ) + ( category.groups?.length || 0 )}
                              />
                            )

                          )}
                      </Row>
                    </React.Fragment>;

                    return {
                      label,
                      children,
                      key:         label,
                      forceRender: true,
                    };
                  } )
                    .filter( ( tab ) => !!tab )
                  }
                />

              </IndexContext.Provider>
            </div>
          )
    }
  </>;
};
