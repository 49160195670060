import { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Badge, Button, Col, Flex, Popover, Row, Select } from 'antd';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import { TextPopover } from '../../../TextPopover/TextPopover';
import { BarChartDiagram } from '../BarChartDiagram/BarChartDiagram';
import { useAudienceShortName } from '../../AudienceShortName';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty.svg';
import { DownOutlined, ExportOutlined, UpOutlined } from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';

import './AttributeContent.scss';
import { EView } from '../AttributesComparison';
import { Attribute, AttributeGroup } from '../../../../apollo/graphql-types';
import { colorPallette } from '../BarChartDiagram/barChartConfig';
import { AudienceNamesContext } from '../../FilledAudienceComparison';

interface IAttributeContentProps {
  attribute: Attribute | AttributeGroup;
  view: EView;
  itemsCount: number;
}

export const AttributeContent = ( { attribute, view, itemsCount }: IAttributeContentProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const titleRef = useRef<HTMLDivElement>( null );
  const comparisonLegendRef = useRef<HTMLDivElement>( null );
  const parentDiagramRef = useRef<HTMLDivElement>( null );

  const [showAllValues, setShowAllValues] = useState( false );

  const isBenchmarkGroup = (
    attribute: Attribute | AttributeGroup
  ): attribute is AttributeGroup => ( attribute as AttributeGroup ).attributes !== undefined;

  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(
    isBenchmarkGroup( attribute ) ? attribute.attributes?.[0] : attribute
  );

  const attributeLowCoverage = selectedAttribute.lowCoverage?.every( ( lowCoverage ) => lowCoverage.coverageValue === 0 );

  const hasMore = ( selectedAttribute?.attributeValues.length || 0 ) > 5 && !attributeLowCoverage;

  const filter = ( node: HTMLElement ) => {
    const exclusionClasses = ['show-more', 'export-button', 'anticon-info-circle', 'show-all-labels'];
    return !exclusionClasses.some( ( classname ) => node.classList?.contains( classname ) );
  };

  const handleExportDiagram = () => {
    if ( !!parentDiagramRef.current ) {
      const attributeName = `${
        isBenchmarkGroup( attribute ) ? `${attribute.groupName}_${selectedAttribute.attributeName}` : attribute.attributeName
      }`;
      htmlToImage.toJpeg( parentDiagramRef.current, { backgroundColor: '#ffffff', filter: filter } )
        .then( function ( dataUrl ) {
          const link = document.createElement( 'a' );
          link.download = `CDS_${attributeName}.jpeg`;
          link.href = dataUrl;
          link.click();
        } );
    }
  };

  useEffect( () => {
    setSelectedAttribute( isBenchmarkGroup( attribute ) ? attribute.attributes?.[0] : attribute );
  }, [attribute] );

  return <Col
    key={selectedAttribute?.attributeName}
    style={{
      '--values-height':  `${parentDiagramRef.current?.scrollHeight}px`,
      '--headers-height': `${( titleRef.current?.scrollHeight || 0 ) + ( comparisonLegendRef.current?.scrollHeight || 0 )}px`,
    } as React.CSSProperties
    }
    className={cn( 'attribute-content', {
      expanded:     showAllValues,
      'has-more':   hasMore,
      'grid-view':       view === EView.grid,
      even:        itemsCount % 2 === 0,
    } )}
    span={view === EView.grid ? 12 : 24}
  >

    <div className='attribute-content-wrapper'>
      <div ref={parentDiagramRef} className='attribute-content-inner'>
        <Row gutter={24}>
          <Col span={12} className='group-title' ref={titleRef}>
            <TitleWithCaption headingLevel={5} heading={ isBenchmarkGroup( attribute ) ? attribute.groupName : attribute.attributeName} />
            {
              isBenchmarkGroup( attribute ) && ( attribute.attributes?.length || 0 ) === 1
          && <TitleWithCaption headingLevel={5} heading={selectedAttribute?.attributeName} />
            }
          </Col>
          <Col span={12}>
            <Flex justify='end' align='end' gap={4} style={{ height: '100%', paddingBottom: 7 }}>
              {isBenchmarkGroup( attribute ) && ( attribute.attributes?.length || 0 ) > 1 && (
                <Select
                  defaultValue={selectedAttribute?.attributeName}
                  className='select-attribute-to-show'
                  style={{ width: 320 }}
                  value={selectedAttribute?.attributeName}
                  onChange={( value ) => {
                    setShowAllValues( false );

                    const attributeToSet = attribute.attributes?.find( ( attr ) => attr.attributeName === value );

                    if ( !!attributeToSet ) {
                      setSelectedAttribute( attributeToSet );
                    }
                  }}
                  options={
                    attribute.attributes?.map( ( attr ) => ( {
                      value: attr.attributeName,
                      label: attr.attributeName,
                    } ) )
                  }
                />
              ) }
              {
                ( !attributeLowCoverage )
              && <Button className='export-button' icon={<ExportOutlined />} onClick={handleExportDiagram}>Export</Button>
              }
            </Flex>
          </Col>

          {!attributeLowCoverage && (
            <Col span={24}>
              <div className='bar-chart-legend'>
                {
                  selectedAttribute.attributeValues[0].segmentValues.map( ( segment, index ) => {
                    const lowCoverage = selectedAttribute.lowCoverage
                      ?.find( ( lowCoverage ) => lowCoverage.coverageValue !== 0 && lowCoverage.segmentId === segment.segmentId );

                    return (
                      <span
                        key={`${selectedAttribute.attributeName}-${index}}`
                        }
                        className={cn( 'legend-color' )}
                        style={{ '--legend-color':  colorPallette[index] } as React.CSSProperties}
                      >
                        <TextPopover
                          text={Object.values( audienceNames )[index]}
                        />
                        { lowCoverage && (
                          <Popover
                            arrow={false}
                            placement='bottom'
                            content={<><Badge status='error' /> Low data coverage: {lowCoverage.coverageValue}%</>}
                          >
                            <Badge status='error' />
                          </Popover>
                        )}
                      </span>
                    );
                  } )
                }
              </div>
            </Col>
          )}
          <Col span={24}>
            <div className='diagram-content-wrapper'>
              {
                !attributeLowCoverage
                  ? <BarChartDiagram attributes={selectedAttribute?.attributeValues || []} />
                  : (
                    <Flex className='empty'>
                      <EmptyIcon />
                      <p className='text'>Attribute is not present for selected audiences</p>
                    </Flex>
                  )
              }
            </div>
          </Col>
          {
            hasMore && (
              <div className='show-more'>
                <Button onClick={() => setShowAllValues( !showAllValues )}>
                  {showAllValues
                    ? <>Show Less Values <UpOutlined /></>
                    : <>Show All Values <DownOutlined /></>}
                </Button>
              </div>
            )
          }
        </Row>
      </div>
    </div>
  </Col>;
};
