import { useSelector } from 'react-redux';
import { AttributesCalcInitResult, AudienceSummaryCard } from '../../../apollo/graphql-types';
import { generateTabName } from '../../Blade/WizardTabs/utils';
import { useAudienceShortName } from '../AudienceShortName';
import { selectScenario } from '../../../store/wizzard/selectors';
import { selectAudienceComparisonOnly } from '../../../store/audienceComparison/selectors';
import { getAudiencesSegmentPositionMap } from '../../../utils/audiences.utils';
import { TextPopover } from '../../TextPopover/TextPopover';
import { AUDIENCE_TO_AUDIENCE_ID } from '../../Blade/collapse/const';
import { useContext, useState } from 'react';
import { Button } from 'antd';
import { SummaryCardDetails } from '../SummaryCardsDetails/SummaryCardDetails';

import './SummaryCard.scss';
import { DefaultAttributes } from '../DefaultAttributes/DefaultAttributes';
import { SubmitForActivation } from './SubmitForActivation';
import { AudienceNamesContext } from '../FilledAudienceComparison';


interface ISummaryCardProps {
  card: AudienceSummaryCard;
  requestId?: string;
  dataInitiateAttributes: AttributesCalcInitResult;
}

export const SummaryCard = ( { card, dataInitiateAttributes }: ISummaryCardProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const selectedScenario = useSelector( selectScenario );
  const segments = useSelector( selectAudienceComparisonOnly );
  const segmentPositionMap = getAudiencesSegmentPositionMap( segments );

  const [open, setOpen] = useState( false );

  const handleCloseDetails =() => {
    setOpen( false );
  };

  return <div className='attribute-summary-item' key={card.audiencePosition}>
    <div className='attribute-summary-section'>
      <span className='title'>{generateTabName( selectedScenario, AUDIENCE_TO_AUDIENCE_ID[card.audiencePosition] )}</span><br />
      <div className='name-count'>
        <TextPopover
          text={`${audienceNames[AUDIENCE_TO_AUDIENCE_ID[card.audiencePosition]]}:`}
          maxWidth={640}
          className='name'
        />
        <b>{card.audienceSize.toLocaleString( 'en-US' )}</b>
      </div>
      {!!dataInitiateAttributes.requestId && <SubmitForActivation
        requestId={dataInitiateAttributes.requestId}
        segmentId={segmentPositionMap[card.audiencePosition]}
        position={card.audiencePosition}
      />}
    </div>

    <div className='attribute-summary-section center'>
      {/* <p className='short-description'>{card.shortDescription}</p> */}
      <DefaultAttributes defaultAttributes={card.defaultAttributes} />
    </div>
    <div className='attribute-summary-section bottom'>
      <Button onClick={() => setOpen( true )}>See Details</Button>
      <SummaryCardDetails
        dataInitiateAttributes={dataInitiateAttributes}
        open={open}
        audienceId={AUDIENCE_TO_AUDIENCE_ID[card.audiencePosition]}
        handleClose={handleCloseDetails}
        name={generateTabName( selectedScenario, AUDIENCE_TO_AUDIENCE_ID[card.audiencePosition] )}
      />
    </div>
  </div>;
};
