import './Summary.scss';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AUDIENCE_ID } from '../collapse/const';
import { selectTabs } from '../../../store/tab/selectors';
import { Tags } from '../../Tags/Tags';
import { selectAudiencesToCompare } from '../../../store/audienceComparison/selectors';

interface ISummaryProps {
  audienceToShow?: AUDIENCE_ID;
}

const Summary = ( { audienceToShow }: ISummaryProps ) => {
  const tabs = useSelector( selectTabs );
  const audiencesToCompare: { [key: string]: any } = useSelector( selectAudiencesToCompare );

  const audiences = Object.entries( audiencesToCompare )
    .filter( ( audience ) => !!audienceToShow ? audienceToShow === audience[0] : true )
    .map( ( [key, audience] ) => tabs[key as AUDIENCE_ID]?.isFinished || !!audienceToShow ? audience : key );

  return (
    <div className='summary-wizard'>
      <TitleWithCaption heading='Summary' />
      <Space className='collapse-tag__wrapper summary-tags' wrap style={{ maxWidth: 640, display: 'flex', gap: 0 }}>
        <Tags audiences={audiences} visible={true} />
      </Space>
    </div>
  );
};

export default Summary;
