import { useState } from 'react';
import { useSelector } from 'react-redux';
import AudienceComparisonEmpty from '../components/AudienceComparisonEmpty/AudienceComparisonEmpty';
import FilledAudienceComparison from '../components/AudienceComparison/FilledAudienceComparison';
import SharedLayout from './SharedLayout';
import Blade from '../components/Blade/bladeCommon/Blade';
import { setWizardIsOpen } from '../store/wizzard/wizardSlice';
import { selectIsWizardOpen } from '../store/wizzard/selectors';
import {
  selectAudienceComparisonCount,
  selectAudienceComparisonIsLoading,
} from '../store/audienceComparison/selectors';

const AudienceComparison = () => {
  const [currentStep, setCurrentStep] = useState( 0 );

  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const isWizardOpen = useSelector( selectIsWizardOpen );
  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );

  const handleNext = () => {
    setCurrentStep( currentStep + 1 );
  };

  const handlePrev = () => {
    setCurrentStep( currentStep - 1 );
  };


  return (
    <>
      <SharedLayout />
      {audienceComparisonCount || isAudienceComparisonLoading ? <FilledAudienceComparison /> : <AudienceComparisonEmpty />}
      <Blade
        open={isWizardOpen}
        currentStep={currentStep}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </>
  );
};

export default AudienceComparison;
