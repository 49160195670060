import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import StyledHeader from '../components/PageHeader/StyledHeader';

const Home = () => (
  <Layout>
    <StyledHeader />
    <Outlet />
  </Layout>
);
export default Home;
