import { EllipsisOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Dropdown, message } from 'antd';
import { AUDIENCE_TO_AUDIENCE_ID, options } from '../../Blade/collapse/const';
import { useSubmitAudienceForActivationMutation } from '../../../apollo/graphql-types';
import { useAudienceShortName } from '../AudienceShortName';
import { AudienceNamesContext } from '../FilledAudienceComparison';
import { useContext } from 'react';

interface ISubmitForActivationProps {
  requestId: string;
  position: string;
  segmentId: string;
}

export const SubmitForActivation = ( { requestId, position, segmentId }: ISubmitForActivationProps ) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [submitForActivation] = useSubmitAudienceForActivationMutation( options() );
  const audienceNames = useContext( AudienceNamesContext );
  const submitError = <>Submission failed. Please retry or contact
    <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
  if the issue continues.
  </>;

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: submitError,
    } );
  };

  const successMessage = () => {
    messageApi.open( {
      type:    'success',
      content: 'Submission received. Xi Chen will contact you for the next steps. Thank you for your patience.',
    } );
  };

  const getAudiencesEmailNames = () => 'Only ' + audienceNames[AUDIENCE_TO_AUDIENCE_ID[position]];

  const items = [
    {
      label:   'Submit for Activation',
      icon:    <PoweroffOutlined />,
      key:     '1',
      onClick: () => {
        if ( !requestId || !segmentId ) {
          errorMessage();
          return;
        }

        submitForActivation( { variables: {
          audienceActivation: {
            requestId,
            segmentId,
            submittedAudiences:  getAudiencesEmailNames(),
            selectedAudiences:   Object.values( audienceNames ).join( ' VS ' ),
          },
        } } )
          .then( ( { data, errors } ) => {
            if ( !errors && !!data?.submitAudienceForActivation.authorEmail ) {
              successMessage();
            } else {
              errorMessage();
            }
          } )
          .catch( () => errorMessage() );
      },
    },
  ];

  return <div className='submit-for-activation'>
    {contextHolder}
    <Dropdown
      menu={{ items }}
      placement='bottom'
      trigger={['click']}
    >
      <Button type='text' icon={<EllipsisOutlined />} />
    </Dropdown>
  </div>;
};
