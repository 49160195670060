import { useEffect, useState } from 'react';
import {
  ALL_BRANDS,
  ALL_BUYER_TYPES,
  ALL_MANUFACTURERS,
  AUDIENCE_ID,
  COMPETITORS,
  getSortedBrandList,
  MARS_BRANDS,
  options,
  PREBUILT_AUDIENCES,
} from '../Blade/collapse/const';

import { selectAppliedAudiencesToCompare } from '../../store/audienceComparison/selectors';
import {
  collectBrandsNames,
  collectManufacturerNames,
  getManufacturersByCategoryId,
  isMarsManufacturer,
} from '../../utils/brand.utils';
import { partyData, webLinks } from './const';
import { EPartyData } from '../Blade/collapse/OriginCollapse/const';
import { useSelector } from 'react-redux';
import { Entries } from '../../store/typings';
import { buyerTypeToTagName, engagementToTagName } from '../Blade/collapse/AdditionalParametersCollapse/const';
import {
  D2C,
  useBrandMarketingCampaignsLazyQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../apollo/graphql-types';

import { BuyerType } from '../../typings';
import fromPairs from 'lodash/fromPairs';
import flatten from 'lodash/flatten';

export const getBuyerTypeName = ( buyerTypes: BuyerType[] ) => {
  if ( !buyerTypes.length ) {
    return '';
  }
  if ( buyerTypes.length === 5 ) {
    return ALL_BUYER_TYPES;
  }

  const buyers = buyerTypes.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );

  if ( buyerTypes.length === 1 && buyerTypes[0] === BuyerType.NonBuyer ) {
    return buyers;
  }

  return buyers.concat( ' Buyer' );
};

export const useAudienceShortName = () => {
  const audiencesToCompare = useSelector( selectAppliedAudiencesToCompare );

  const [names, setNames] = useState<Record<AUDIENCE_ID, string>>(
    { [AUDIENCE_ID.AUDIENCE1]: 'loading', [AUDIENCE_ID.AUDIENCE2]: 'loading', [AUDIENCE_ID.AUDIENCE3]: 'loading' }
  );

  const [loadPreBuiltAudiences] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadMarsBrands] = useGetBrandsLazyQuery( options() );
  const [loadCompetitors] = useGetBrandsLazyQuery( { ...options(), variables: { isCompetitor: true } } );
  const [loadCampaigns] = useBrandMarketingCampaignsLazyQuery( options() );

  const renderName = async () => {
    const audiences = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> )
      .filter( ( [key, audience] ) => !!audience?.audienceParameters || !!audience?.preBuiltAudienceIds );

    const namesToShow = await Promise.all( audiences.map( async ( [key, audience] ) => {
      const audienceParameters = audience.audienceParameters;
      const preBuiltAudienceIds = audience.preBuiltAudienceIds;

      if ( !!preBuiltAudienceIds ) {
        const preBuiltAudiences = await loadPreBuiltAudiences();
        const preBuildValues = preBuiltAudienceIds.map( ( id ) =>
          preBuiltAudiences.data?.preBuiltAudienceNames.find( ( audience ) => audience.id === id )?.name || 'loading' );

        return [
          key,
          `${PREBUILT_AUDIENCES} ${preBuildValues.join( preBuildValues.length < 2 ? '' : ', ' )}`,
        ];
      }

      if ( !!audienceParameters ) {
        if ( !isMarsManufacturer( audience.audienceParameters?.manufacturers ) ) {
          const competitorsData = await loadCompetitors();
          const competitors = competitorsData.data?.categorizedBrands;
          const competitorsManufacturerNames = collectManufacturerNames( competitors || [] );
          const manufacturersList = getManufacturersByCategoryId( audienceParameters.category, competitors ) || [];
          const selectedBrands = audienceParameters?.brands || [];
          const competitorsBrandNames = collectBrandsNames( competitors || [] );
          const availableBrands = flatten( competitors
            ?.find( ( category ) => category.categoryId === audienceParameters?.category )
            ?.manufacturers
            .filter( ( manufacturer ) => audienceParameters?.manufacturers.includes( manufacturer.manufacturerId ) )
            .map( ( manufacturer ) => manufacturer.brands ) );
          const selectedCategoryName = competitors
            ?.find( ( category ) => audienceParameters.category === category.categoryId )?.categoryName || '';
          const isAllBrandsSelected = availableBrands?.length === selectedBrands?.length;
          const isAllManufacturersSelected = manufacturersList?.length === audienceParameters?.manufacturers?.length;
          const SOME_MANUFACTURERS_CAPTION = audienceParameters.manufacturers
            ?.map( ( manufacturer ) => competitorsManufacturerNames[manufacturer] ).join( ', ' );
          const ALL_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION} ${ALL_BRANDS}`;
          const SOME_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION}
           ${getSortedBrandList( selectedBrands, availableBrands )?.map( ( brand ) => competitorsBrandNames[brand] ).join( ', ' )}`;

          const brandTagValue = `${selectedCategoryName} 
          ${isAllBrandsSelected && availableBrands.length > 1 ? [ALL_BRANDS_CAPTION] : SOME_BRANDS_CAPTION}`;

          return [
            key,
            `${COMPETITORS} ${brandTagValue} ${getBuyerTypeName( audienceParameters.buyerTypes || [] )}`,
          ];
        } else {
          const originValue = audienceParameters.firstParty === true
            ? EPartyData.firstParty
            : audienceParameters.thirdParty === true
              ? EPartyData.thirdParty
              : EPartyData.none;

          const marsBrandsData = await loadMarsBrands();
          const marsBrands = marsBrandsData.data?.categorizedBrands;
          const marsBrandNames = collectBrandsNames( marsBrands || [] );
          const selectedBrands = audienceParameters?.brands || [];
          const marketingCampaigns = audienceParameters?.marketingCampaigns;

          let campaigns: D2C | null | undefined = null;

          if ( originValue === EPartyData.firstParty ) {
            const brandsToLoad = selectedBrands.map( ( brandId ) => {
              const name = marsBrandNames[brandId];
              return {
                id: brandId,
                name,
              };
            } );

            const campaignsData = await loadCampaigns( { variables: { brands: brandsToLoad || [] } } );
            campaigns = campaignsData.data?.brandMarketingCampaigns;
          }

          const engagement = !!audienceParameters?.engagementStatus ? engagementToTagName[audienceParameters.engagementStatus] : '';

          const d2c = audienceParameters?.d2c;
          const origin = originValue !== EPartyData.none && partyData[originValue];
          const category = audienceParameters?.category;
          const selectedCategoryName = marsBrands
            ?.find( ( categoryItem ) => category === categoryItem.categoryId )?.categoryName || '';
          const categorizedBrandsByCategory = marsBrands
            ?.find( ( categoryItem ) => categoryItem.categoryId === category )?.manufacturers[0].brands;

          const campaignsToShow = marketingCampaigns?.map( ( id ) =>
            campaigns?.no?.find( ( campaign ) => campaign.id === id )?.name ).filter( ( item ) => !!item ).join( ', ' );

          let brandNamesToShow = selectedBrands
            ?.filter( ( brand ) => {
              if ( marketingCampaigns?.length || 0 > 0 ) {
                return !!marketingCampaigns?.map( ( selectedCampaign ) =>
                  campaigns?.no?.find( ( c ) => c.id === selectedCampaign && c.brandName === marsBrandNames?.[brand] ) )
                  .filter( ( item ) => !!item ).length;
              } else if ( !!d2c ) {
                return !!webLinks[brand];
              } else if ( originValue === EPartyData.firstParty ) {
                const hasCampaigns = !!campaigns?.no?.find( ( c ) => c.brandName === marsBrandNames?.[brand] );
                const hasD2C = !!campaigns?.yes?.find( ( c ) => c.brandName === marsBrandNames?.[brand] );

                return hasCampaigns || hasD2C;
              }

              return true;
            } )
            .map( ( brand ) => !!d2c ? webLinks[brand] : marsBrandNames?.[brand] )
            .join( ', ' );

          brandNamesToShow = brandNamesToShow?.split( ',' ).length === categorizedBrandsByCategory?.length
            ? `${selectedCategoryName} ${ALL_BRANDS}`
            : `${selectedCategoryName} ${brandNamesToShow}`;

          return [
            key,
            `${MARS_BRANDS} ${brandNamesToShow} ${!!campaignsToShow?.length ? campaignsToShow + ' ' + engagement : !!origin && !d2c && !marketingCampaigns ? origin : ''} ${getBuyerTypeName( audienceParameters?.buyerTypes || [] )}`.trim() // eslint-disable-line
          ];
        }
      }
    } ) );

    // @ts-ignore
    return setNames( fromPairs( namesToShow ) );
  };

  useEffect( () => {
    renderName();
  }, [audiencesToCompare] );

  return names;
};
