import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { TextPopover } from '../../../TextPopover/TextPopover';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './BarChartDiagram.scss';
import { generateBenchmarkData, optionsBenchmark } from './barChartConfig';
import { memo, useContext } from 'react';
import { AttributeValue } from '../../../../apollo/graphql-types';
import { useSelector } from 'react-redux';
import { selectAudienceComparisonOnly } from '../../../../store/audienceComparison/selectors';
import { IndexContext } from '../AttributesComparison';
import cn from 'classnames';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartDataLabels,
  Tooltip
);

interface IBarChartDiagramProps {
  attributes: AttributeValue[];
}

export const BarChartDiagram = memo( function ( { attributes }: IBarChartDiagramProps ) {
  const segments = useSelector( selectAudienceComparisonOnly );
  const showIndex = useContext( IndexContext );
  const data = generateBenchmarkData( attributes, segments );
  const options = optionsBenchmark( showIndex );

  return (
    <div className={cn( 'bar-chart-diagram', {
      'with-index': showIndex,
    } )}>
      <ul className='diagram-labels'>
        {
          data.labels?.map( ( label ) =>
            <li
              key={label as 'string'}
              className='label'
              style={{ '--label-height': `${segments.length * 14}px` } as React.CSSProperties}
            >
              <div className='label-wrapper'>
                <TextPopover maxHeight={36} maxWidth={600} text={label as 'string'} />
              </div>
            </li>
          )
        }
      </ul>

      <div
        className='bar-diagram'
        style={{ height: ( data.labels?.length || 1 ) * ( segments.length * 14 + 18 ) }
          // 14 - it's a height of bar (11.5) + gap between bars (2.5) -> 11.5 + 2.5 = 14
          // 18 - gaps around one bar chart category, 9 + 9 = 18
        }
      >
        {
          showIndex && <span className='index-label'>Index</span>
        }
        <Bar options={options} data={data} />
      </div>
    </div>
  );
} );
