/* eslint-disable max-len */

import { useDispatch } from 'react-redux';
import { AUDIENCE_ID } from '../Blade/collapse/const';
import { setActiveTab } from '../../store/tab/tabSlice';
import { setWizardIsOpen } from '../../store/wizzard/wizardSlice';

const EmptyVenn = () => {
  const dispatch = useDispatch();

  const showModal = ( audience: string ) => {
    dispatch( setActiveTab( audience ) );
    dispatch( setWizardIsOpen( true ) );
  };

  return (
    <div className='default-venn'>
      <svg
        className='default-venn-container'
        viewBox='0 0 620 340'
        width='620'
        height='340'
        aria-label='Audience Comparison Default View'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
      >
        <g className='default-venn-circle'>
          <circle className='default-venn-circle-item' cx='170' cy='170' r='169' />
          <circle className='default-venn-circle-item' cx='450' cy='170' r='169' />
        </g>
      </svg>
      <div className='benchmark parameters'>
        <svg className='icon-empty' width='117' height='72' viewBox='0 0 117 72' fill='none'>
          <path
            d='M17.2655 50.7656C7.16715 53.0084 0.953026 56.0638 0.953026 59.4311C0.953026 66.3144 26.9205 71.8945 58.953 71.8945C90.9855 71.8945 116.953 66.3144 116.953 59.4311C116.953 56.0638 110.739 53.0084 100.641 50.7656V56.1988C100.641 59.9788 98.248 63.0769 95.2937 63.0769H22.6124C19.658 63.0769 17.2655 59.977 17.2655 56.1988V50.7656Z'
            fill='black'
            fillOpacity='0.06'
          />
          <path
            d='M76.3766 29.1249C76.3766 26.2672 78.1782 23.908 80.413 23.9062H100.641V56.1989C100.641 59.9789 98.248 63.077 95.2936 63.077H22.6124C19.658 63.077 17.2655 59.9771 17.2655 56.1989V23.9062H37.493C39.7278 23.9062 41.5295 26.2618 41.5295 29.1195V29.1587C41.5295 32.0164 43.351 34.3239 45.584 34.3239H72.322C74.555 34.3239 76.3766 31.995 76.3766 29.1373V29.1249Z'
            fill='black'
            fillOpacity='0.04'
            stroke='black'
            strokeOpacity='0.16'
          />
          <path
            d='M100.641 24.1018L82.2509 3.62267C81.3682 2.22676 80.0795 1.38281 78.722 1.38281H39.1841C37.8265 1.38281 36.5378 2.22676 35.6552 3.62089L17.2655 24.1036'
            stroke='black'
            strokeOpacity='0.16'
          />
        </svg>
        <p className='select-parameters-link text' onClick={() => showModal( AUDIENCE_ID.AUDIENCE1 )}>
					Select Parameters
        </p>
        <p className='text'>Benchmark Audience</p>
      </div>

      <div className='comparison parameters'>
        <svg className='icon-empty' width='117' height='72' viewBox='0 0 117 72' fill='none'>
          <path
            d='M17.2655 50.7656C7.16715 53.0084 0.953026 56.0638 0.953026 59.4311C0.953026 66.3144 26.9205 71.8945 58.953 71.8945C90.9855 71.8945 116.953 66.3144 116.953 59.4311C116.953 56.0638 110.739 53.0084 100.641 50.7656V56.1988C100.641 59.9788 98.248 63.0769 95.2937 63.0769H22.6124C19.658 63.0769 17.2655 59.977 17.2655 56.1988V50.7656Z'
            fill='black'
            fillOpacity='0.06'
          />
          <path
            d='M76.3766 29.1249C76.3766 26.2672 78.1782 23.908 80.413 23.9062H100.641V56.1989C100.641 59.9789 98.248 63.077 95.2936 63.077H22.6124C19.658 63.077 17.2655 59.9771 17.2655 56.1989V23.9062H37.493C39.7278 23.9062 41.5295 26.2618 41.5295 29.1195V29.1587C41.5295 32.0164 43.351 34.3239 45.584 34.3239H72.322C74.555 34.3239 76.3766 31.995 76.3766 29.1373V29.1249Z'
            fill='black'
            fillOpacity='0.04'
            stroke='black'
            strokeOpacity='0.16'
          />
          <path
            d='M100.641 24.1018L82.2509 3.62267C81.3682 2.22676 80.0795 1.38281 78.722 1.38281H39.1841C37.8265 1.38281 36.5378 2.22676 35.6552 3.62089L17.2655 24.1036'
            stroke='black'
            strokeOpacity='0.16'
          />
        </svg>
        <p className='select-parameters-link text' onClick={() => showModal( AUDIENCE_ID.AUDIENCE2 )}>
					Select Parameters
        </p>
        <p className='text'>Comparison Audience</p>
      </div>
    </div>
  );
};

export default EmptyVenn;
