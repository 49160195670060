import { Alert, Divider, Drawer, Space, Spin } from 'antd';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';

import './SummaryCardDetails.scss';
import { AUDIENCE_ID, AUDIENCE_TO_AUDIENCE_ID, options } from '../../Blade/collapse/const';
import Summary from '../../Blade/Summary/Summary';
import { useAudienceShortName } from '../AudienceShortName';
import { EScenario } from '../../../store/wizzard/wizardSlice';
import { useSelector } from 'react-redux';
import { selectScenario } from '../../../store/wizzard/selectors';
import { AttributesCalcInitResult, useDetailedSummaryCardLazyQuery } from '../../../apollo/graphql-types';
import { useContext, useEffect } from 'react';
import { ErrorRetryCircle } from '../../Error/ErrorRetryCircle';
import { DefaultAttributes } from '../DefaultAttributes/DefaultAttributes';
import { DetailedAttributes } from './DetailedAttributes';
import { IndexDescription } from './IndexDescription';
import { AudienceNamesContext } from '../FilledAudienceComparison';

interface ISummaryCardDetailsProps {
  open: boolean;
  name: string;
  audienceId: AUDIENCE_ID;
  dataInitiateAttributes: AttributesCalcInitResult;
  handleClose: () => void;
}

export const SummaryCardDetails = ( { open, name, audienceId, dataInitiateAttributes, handleClose }: ISummaryCardDetailsProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const scenario = useSelector( selectScenario );

  const detailedRequestData = dataInitiateAttributes.only
    .find( ( segment ) => AUDIENCE_TO_AUDIENCE_ID[segment.position[0]] === audienceId );

  const variables = {
    summaryCardPrerequisites: {
      requestId:           dataInitiateAttributes.requestId,
      segmentId:  detailedRequestData?.segmentId || '',
      queryId:    detailedRequestData?.queryId || '',
    },
  };

  const [loadDetailedSummary, { data: summaryCardDetails, loading, error, refetch }] = useDetailedSummaryCardLazyQuery( {
    ...options(),
    notifyOnNetworkStatusChange: true,
    variables,
  } );

  useEffect( () => {
    if ( open && !!detailedRequestData ) {
      loadDetailedSummary();
    }
  }, [open] );

  return (
    <Drawer
      rootClassName='summary-card-details'
      maskClosable={false}
      title={<TitleWithCaption
        headingLevel={4}
        heading={name}
        headingStyles={{ fontFamily: 'WorkSans-SemiBold' }}
      />}
      placement='right'
      size='large'
      onClose={handleClose}
      open={open}
    >
      {
        error
          ? (
            <div className='error-wrapper'>
              <ErrorRetryCircle
                title='Data Load Failed'
                description={ <>
              Data couldn't be loaded. Please retry or contact
                  <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
             if the issue persists.
                </>}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          )
          : loading
            ? (
              <Space className='loader' size='middle'>
                <Spin />
              </Space>
            )
            : (
              <>
                <Summary audienceToShow={audienceId}/>
                <Divider />
                {/* <p className='summary-card-details-description'>{summaryCardDetails?.detailedSummaryCard.description}</p> */}
                {
                  audienceId !== AUDIENCE_ID.AUDIENCE1 && (
                    <Alert
                      message={
                        `Index is calculated against ${scenario === EScenario.compare
                          ? `Benchmark Audience: ${audienceNames.AudienceA}`
                          : 'National Reference.'
                        }`
                      }
                      type='info'
                      rootClassName='summary-card-details-info-message'
                      action={
                        <IndexDescription />
                      }
                    />
                  )
                }
                {summaryCardDetails?.detailedSummaryCard.defaultAttributes && (
                  <DefaultAttributes defaultAttributes={summaryCardDetails?.detailedSummaryCard.defaultAttributes} />
                )}
                {summaryCardDetails?.detailedSummaryCard.detailedAttributes && (
                  <DetailedAttributes detailedAttributes={summaryCardDetails?.detailedSummaryCard.detailedAttributes} />
                )}
              </>
            )
      }
    </Drawer>
  );
};
