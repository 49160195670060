import { CheckOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Popover, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import {
  collectBrandsNames,
  collectManufacturerNames,
  getManufacturersByCategoryId,
  isMarsManufacturer,
} from '../../../../utils/brand.utils';
import { setSpecificTab } from '../../../../store/tab/tabSlice';
import { collapseAllWizards, setCompetitorsWizard } from '../../../../store/wizzard/wizardSlice';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { TAG_VALUES, WIZARD_NAME } from '../../types';
import { buyerTypeToTagName } from '../AdditionalParametersCollapse/const';
import CollapseHeader from '../CollapseHeader';
import {
  getSortedBrandList,
  ALL_BUYER_TYPES,
  tooltipContent,
  BUYER_TYPE_DESCRIPTION,
  AUDIENCE_ID,
  options,
  stepErrorDescription,
  ALL_BRANDS,
  ALL_MANUFACTURERS,
} from '../const';
import { ICollapseProps } from '../types';
import { selectWizardCompetitorsByTabName } from '../../../../store/wizzard/selectors';
import {
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectBuyerTypesByAudienceId,
  selectCategoryByAudienceId,
  selectHasAudiencesToCompare,
  selectIsEqualComparisons,
  selectManufacturerByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { BuyerType } from '../../../../typings';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import { Manufacturer, useGetBrandsQuery } from '../../../../apollo/graphql-types';
import { handleScrollIntoView } from '../utils';
import flatten from 'lodash/flatten';
import cn from 'classnames';

import './CompetitorsCollapse.scss';


interface ICompetitorsCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const CompetitorsCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: ICompetitorsCollapseProps ) => {
  const dispatch = useDispatch();

  const { data: competitorsData, loading, error, refetch } = useGetBrandsQuery( {
    ...options(),
    variables:                   { isCompetitor: true },
    notifyOnNetworkStatusChange: true,
  } );

  const competitorsWizard = useSelector( selectWizardCompetitorsByTabName( activeTab ) );
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );
  const selectedManufacturer = useSelector( selectManufacturerByAudienceId( activeTab ) );
  const selectedBuyerTypes = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBrands = useSelector( selectBrandsByAudienceId( activeTab ) );
  const manufacturersList = getManufacturersByCategoryId( selectedCategory, competitorsData?.categorizedBrands ) || [];
  const competitorsList = competitorsData?.categorizedBrands || [];
  const manufacturerNames = collectManufacturerNames( competitorsList || [] );
  const competitorsBrandsNames = collectBrandsNames( competitorsData?.categorizedBrands || [] );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const [showAllBrands, setShowAllBrands] = useState( false );
  const availableBrands = flatten( manufacturersList.filter( ( manufacturer: Manufacturer ) =>
    selectedManufacturer?.includes( manufacturer.manufacturerId ) ).map( ( manufacturer ) => manufacturer.brands ) );
  const isAllTypesSelected = selectedBuyerTypes?.length === 5;
  const isMultipleBrands = availableBrands.length > 1;
  const selectedCategoryName = competitorsList
    ?.find( ( category ) => selectedCategory === category.categoryId )?.categoryName || '';
  const isAllBrandsSelected = availableBrands.length === selectedBrands?.length;
  const isAllManufacturersSelected = manufacturersList.length === selectedManufacturer?.length;
  const SOME_MANUFACTURERS_CAPTION = selectedManufacturer?.map( ( manufacturer ) => manufacturerNames[manufacturer] ).join( ', ' );
  const ALL_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION} ${ALL_BRANDS}`;
  const SOME_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION}
   ${getSortedBrandList( selectedBrands, availableBrands )?.map( ( brand ) => competitorsBrandsNames[brand] ).join( ', ' )}`;

  const brandTagValue = `${selectedCategoryName} ${isAllBrandsSelected && isMultipleBrands ? [ALL_BRANDS_CAPTION] : SOME_BRANDS_CAPTION}`;

  useEffect( () => {
    if ( !!error?.message ) {
      console.error(`Loading data error: ${error.message}`); //eslint-disable-line
    }
  }, [error] );
  const scrollRef = useRef<HTMLDivElement | null>( null );

  const handleCategoryClick = ( id: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    setShowAllBrands( false );
    if ( selectedCategory !== id ) {
      dispatch( setAudienceParameters( [activeTab, { brands: [], buyerTypes: [] }] ) );
    }
    dispatch( setAudienceParameters( [activeTab, { category: id, manufacturers: undefined }] ) );
  };

  const handleManufacturerClick = ( id: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedManufacturer?.includes( id ) ) {
      dispatch( setAudienceParameters( [activeTab, {
        manufacturers: selectedManufacturer.filter( ( selectedId ) => selectedId !== id ),
        brands:        [],
        buyerTypes:    [],
      }] ) );
    } else {
      dispatch( setAudienceParameters( [activeTab, {
        manufacturers: [...( selectedManufacturer || [] ), id],
        brands:        [],
        buyerTypes:    [],
      }] ) );
    }
  };

  const handleBrandClick = ( name: number ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBrands?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { brands: selectedBrands.filter( ( brand ) => brand !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { brands: [...( selectedBrands || [] ), name] }] ) );
  };

  const handleClickAllBrands = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( availableBrands.length === selectedBrands?.length ) {
      dispatch( setAudienceParameters( [activeTab, { brands: [] }] ) );
      return;
    }

    dispatch( setAudienceParameters( [activeTab, { brands:  availableBrands.map( ( brand ) => brand.brandId ) }] ) );
  };

  const handleClickAllManufacturers = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( manufacturersList.length === selectedManufacturer?.length ) {
      dispatch( setAudienceParameters( [activeTab, {
        manufacturers: [],
        brands:        [],
        buyerTypes:    [],
      }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, {
      manufacturers:  manufacturersList.map( ( manufacturer ) => manufacturer.manufacturerId ),
      brands:        [],
      buyerTypes:    [],
    }] ) );
  };

  const handleBuyerTypeClick = ( name: BuyerType ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBuyerTypes?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: selectedBuyerTypes.filter( ( buyerType ) => buyerType !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { buyerTypes: [...( selectedBuyerTypes || [] ), name] }] ) );
  };

  const handleClickAllBuyerTypes = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, { buyerTypes: isAllTypesSelected ? [] : Object.values( BuyerType ) }] ) );
  };

  const handleFinishWizardClick = () => {
    dispatch(
      setCompetitorsWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( collapseAllWizards( { activeTab } ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  useEffect( () => {
    if ( !loading || !!selectedCategory || !!selectedManufacturer || !!selectedBrands || !!selectedBuyerTypes ) {
      handleScrollIntoView( scrollRef );
    }
  }, [loading, selectedCategory, selectedManufacturer, selectedBrands, selectedBuyerTypes] );

  useEffect( () => {
    if ( competitorsWizard.isLoading === false && competitorsList.length > 0 && !selectedCategory ) {
      const defaultCategory = competitorsList[0]?.categoryId;
      dispatch( setAudienceParameters( [activeTab, { category: defaultCategory }] ) );
    }
  }, [competitorsWizard.isLoading, competitorsList.length] );

  useEffect( () => {
    if ( hasAudiencesToCompare && isEqualComparisons ) {
      const audience = audiencesToCompare[activeTab];
      const isCompetitorBrandsSource = !isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isCompetitorBrandsSource ) {
        dispatch(
          setCompetitorsWizard( {
            activeTab,
            isFinished: true,
          } ),
        );
        dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
      }
    }
  }, [] );

  const collapseItems = [
    {
      key:   WIZARD_NAME.COMPETITORS,
      label:  <CollapseHeader
        step={WIZARD_NAME.COMPETITORS}
        heading='Step 02: Brand'
        caption='Select a brand to proceed with'
        tags={[
          { name: TAG_VALUES.BRAND, value: brandTagValue },
          {
            name:  TAG_VALUES.BUYER_TYPE,
            value: isAllTypesSelected ? ALL_BUYER_TYPES : selectedBuyerTypes?.map( ( bt ) => buyerTypeToTagName[bt] ) || [],
          },
        ]}
      />,
      children: <div className='competitors-collapse'>
        <Space className='blade-buttons' wrap>
          {/* Categories */}
          {( competitorsList || [] ).map( ( brand ) => (
            <Button
              key={brand.categoryName}
              type={selectedCategory === brand.categoryId ? 'primary' : 'default'}
              className={cn( 'category', { selected: brand.categoryId === selectedCategory } )}
              onClick={() => handleCategoryClick( brand.categoryId )}
            >
              {brand.categoryName}
            </Button>
          ) )}
        </Space>
        {/* Manufacturers */}
        {selectedCategory && (
          <>
            <div className='title manufacturer-title'>
              <TitleWithCaption caption='Select Manufacturer' captionFontSize={16} />
            </div>
            <Button
              key='All Manufacturers'
              type={isAllManufacturersSelected ? 'primary' : 'default'}
              className='all-btn'
              onClick={handleClickAllManufacturers}
            >
                  All Manufacturers
            </Button>
            <Space className='blade-buttons' wrap>
              {manufacturersList.map( ( manufacturer ) => {
                const { manufacturerName, manufacturerId } = manufacturer;
                return (
                  <Button
                    key={manufacturerName}
                    className={cn( { selected:  selectedManufacturer?.includes( manufacturerId ) } )}
                    onClick={() => handleManufacturerClick( manufacturerId )}
                    icon={selectedManufacturer?.includes( manufacturerId ) ? <CheckOutlined /> : ''}
                  >
                    {manufacturerName}
                  </Button>
                );
              } )}
            </Space>
          </>
        )}

        <div ref={scrollRef}>
          {/* All Brands */}
          {!!selectedManufacturer?.length && (
            <>
              <Divider className='devider' />
              <div className='title'>
                <TitleWithCaption caption='Select Brand' captionFontSize={16} />
              </div>
              {isMultipleBrands && (
                <Button
                  key={ALL_BRANDS}
                  type={isAllBrandsSelected ? 'primary' : 'default'}
                  className='all-btn'
                  onClick={handleClickAllBrands}
                >
                  {ALL_BRANDS}
                </Button>
              )}
              {/* Brands */}
              <Space className='blade-buttons' wrap>
                {availableBrands?.slice( 0, showAllBrands ? availableBrands.length : 10 )?.map( ( brand ) => {
                  const { brandName, brandId } = brand;
                  return (
                    <Button
                      key={brandName}
                      className={cn( { selected: selectedBrands?.includes( brandId ) } )}
                      icon={selectedBrands?.includes( brandId ) ? <CheckOutlined /> : ''}
                      onClick={() => handleBrandClick( brandId )}
                    >
                      {brandName}
                    </Button>
                  );
                } )}
                {
                  !showAllBrands
                  && !!selectedManufacturer?.length
                  && ( availableBrands?.length || 0 ) >= 10
                  && (
                    <div className='link-button'>
                      <Button type='link' onClick={() => setShowAllBrands( true )}>
											Show All
                      </Button>
                    </div>
                  )}
              </Space>
              {( selectedBrands?.length || 0 ) > 0 && (
                <>
                  <Divider className='devider' />
                  <div className='title'>
                    <span className='buyer_type-info'>Buyer Type</span>
                    <Popover content={tooltipContent( BUYER_TYPE_DESCRIPTION )} trigger='hover' placement='bottom'>
                      <InfoCircleOutlined className='buyer_type-info-icon' />
                    </Popover>
                  </div>
                  <Button
                    key='All Buyer Types'
                    type={isAllTypesSelected ? 'primary' : 'default'}
                    className='all-btn'
                    onClick={handleClickAllBuyerTypes}
                  >
                    {ALL_BUYER_TYPES}
                  </Button>
                  <Space className='blade-buttons' wrap>
                    {Object.values( BuyerType ).map( ( buyerType ) => {
                      const isSelected = selectedBuyerTypes?.includes( buyerType );

                      return (
                        <Button
                          key={buyerType}
                          className={cn( { selected:  isSelected } )}
                          icon={isSelected ? <CheckOutlined /> : ''}
                          onClick={() => handleBuyerTypeClick( buyerType )}
                        >
                          {buyerTypeToTagName[buyerType]}
                        </Button>
                      );
                    } )}
                  </Space>
                  {( selectedBuyerTypes?.length || 0 ) > 0 && (
                    <>
                      <Divider className='devider' />
                      <div className='next-button'>
                        <Button onClick={handleFinishWizardClick}>Finish Wizard</Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>,
    },
  ];

  return error?.message
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          refetch();
        }}
      />
    )
    : loading
      ? <BladeLoader />
      : <Collapse
        items={collapseItems}
        defaultActiveKey={WIZARD_NAME.COMPETITORS}
        activeKey={competitorsWizard.isExpand ? WIZARD_NAME.COMPETITORS : ''}
        collapsible='icon'
        expandIconPosition='end'
        onChange={() => handleCollapseChange( setCompetitorsWizard, competitorsWizard )}
        expandIcon={() =>
          competitorsWizard.isExpand
            ? (
              <MinusOutlined style={expandIconStyles} />
            )
            : (
              <PlusOutlined style={expandIconStyles} />
            )
        }
      />;
};
export default CompetitorsCollapse;
