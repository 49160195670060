import { useState, useEffect, useRef } from 'react';
import { Popover } from 'antd';

import './TextPopover.scss';

type TTextPopoverProps = {
  text?: string;
  maxWidth?: number;
  maxHeight?: number;
} & JSX.IntrinsicElements['div'];

export const TextPopover = ( { text, maxWidth, maxHeight, children, className }: TTextPopoverProps ) => {
  const ref = useRef<HTMLSpanElement | null>( null );
  const [isOverflowed, setIsOverflowed] = useState( true );

  useEffect( () => {
    const text = ref.current;
    if ( text ) {
      const isTextOverflowed = !!maxHeight ? text?.offsetHeight < text?.scrollHeight : text?.offsetWidth < text?.scrollWidth;
      setIsOverflowed( isTextOverflowed );
    }
  } );

  return isOverflowed
    ? <Popover overlayStyle={{ maxWidth: maxWidth ?? ref.current?.offsetWidth }} content={text} placement='bottom'>
      <span ref={ref} className={`text-popover-${!!maxHeight ? 'height' : 'width'} ${className}`}>
        <span>{children ?? text}</span>
      </span>
    </Popover>
    : <span ref={ref} className={`text-popover-${!!maxHeight ? 'height' : 'width'} ${className}`}><span>{children ?? text}</span></span>;
};
