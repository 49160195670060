import { createSlice } from '@reduxjs/toolkit';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { omit } from 'lodash';


export interface ICommonWizardInterface {
  isFinished: boolean;
  isExpand: boolean;
  isVisible: boolean;
  isLoading: boolean;
}

export interface ISourceWizardInterface extends ICommonWizardInterface {
  value: string;
}

export interface IMarsBrandsWizardInterface extends ICommonWizardInterface {
}
export interface IAdditionalParametersWizardInterface extends ICommonWizardInterface {
  value: string;
}


export interface ITabState {
  source: ISourceWizardInterface;
  prebuiltAudience: ICommonWizardInterface;
  marsBrands: IMarsBrandsWizardInterface;
  origin: ICommonWizardInterface;
  additionalParameters: IAdditionalParametersWizardInterface;
  competitors: ICommonWizardInterface;
}

export enum EScenario {
  compare = 'compare',
  single = 'single',
  overlap = 'overlap',
  exclusive1 = 'exclusive1',
  exclusive2 = 'exclusive2',
  exclusive3 = 'exclusive3',
}

export interface IWizardState {
  isOpen: boolean;
  wizards: Record<AUDIENCE_ID, ITabState>;
  scenario: EScenario;
}

const commonWizardState = {
  isFinished: false,
  isExpand:   false,
  isVisible:  false,
  isLoading:  false,
};

const defaultSourceState: ISourceWizardInterface = {
  ...commonWizardState,
  isExpand: true,
  value:    '',
};
const defaultPrebuiltAudienceState: ICommonWizardInterface = {
  ...commonWizardState,
};
const defaultMarsBrandsState: IMarsBrandsWizardInterface = {
  ...commonWizardState,
};
const defaultAdditionalParametersState: IAdditionalParametersWizardInterface = {
  ...commonWizardState,
  value:      '',
};

const initialTabState: ITabState = {
  source:               defaultSourceState,
  prebuiltAudience:     defaultPrebuiltAudienceState,
  marsBrands:           defaultMarsBrandsState,
  competitors:          commonWizardState,
  origin:               commonWizardState,
  additionalParameters: defaultAdditionalParametersState,
};

const initialState: IWizardState = {
  isOpen:   false,
  scenario: EScenario.compare,
  wizards:  {
    [AUDIENCE_ID.AUDIENCE1]:         initialTabState,
    [AUDIENCE_ID.AUDIENCE2]:        initialTabState,
    [AUDIENCE_ID.AUDIENCE3]: initialTabState,
  },
};

export const wizardSlice = createSlice( {
  name:     'wizard',
  initialState,
  reducers: {
    setSingleWizzardById: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload;

      state.wizards = {
        [AUDIENCE_ID.AUDIENCE1]: state.wizards[activeTab],
      } as IWizardState['wizards'];

      return state;
    },
    duplicateWizzardById: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload;

      state.wizards = {
        ...state.wizards,
        [AUDIENCE_ID.AUDIENCE3]: state.wizards[activeTab],
      } as IWizardState['wizards'];

      return state;
    },
    resetWizzardById: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload;
      state.wizards[activeTab] = initialState.wizards.AudienceA;

      return state;
    },
    setWizardIsOpen: ( state, action ) => {
      state.isOpen = action.payload;
    },
    setScenario: ( state, action ) => {
      state.scenario = action.payload;
    },
    collapseAllWizards: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source.isExpand = false;
      state.wizards[activeTab].prebuiltAudience.isExpand = false;
      state.wizards[activeTab].competitors.isExpand = false;
      state.wizards[activeTab].marsBrands.isExpand = false;
      state.wizards[activeTab].origin.isExpand = false;
      state.wizards[activeTab].additionalParameters.isExpand = false;
    },
    resetAllWizards: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = initialState.wizards[activeTab].source;
      state.wizards[activeTab].source = {
        ...state.wizards[activeTab].source,
        isExpand: true,
      };
      state.wizards[activeTab].prebuiltAudience = initialState.wizards[activeTab].prebuiltAudience;
      state.wizards[activeTab].competitors = initialState.wizards[activeTab].competitors;
      state.wizards[activeTab].marsBrands = initialState.wizards[activeTab].marsBrands;
      state.wizards[activeTab].origin = initialState.wizards[activeTab].origin;
      state.wizards[activeTab].additionalParameters = initialState.wizards[activeTab].additionalParameters;
    },
    resetSourceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = initialState.wizards[activeTab].source;
    },
    resetPrebuiltAudienceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].prebuiltAudience = initialState.wizards[activeTab].prebuiltAudience;
    },
    resetMarsBrandsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].marsBrands = initialState.wizards[activeTab].marsBrands;
    },
    resetOriginWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].origin = initialState.wizards[activeTab].origin;
    },
    resetAdditionalParametersWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].additionalParameters = initialState.wizards[activeTab].additionalParameters;
    },
    resetCompetitorsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].competitors = initialState.wizards[activeTab].competitors;
    },

    setSourceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].source = { ...state.wizards[activeTab].source, ...omit( action.payload, 'activeTab' ) };
    },
    setPrebuiltAudienceWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].prebuiltAudience = { ...state.wizards[activeTab].prebuiltAudience, ...omit( action.payload, 'activeTab' ) };
    },
    setMarsBrandsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].marsBrands = { ...state.wizards[activeTab].marsBrands, ...omit( action.payload, 'activeTab' ) };
    },
    setOriginWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].origin = { ...state.wizards[activeTab].origin, ...omit( action.payload, 'activeTab' ) };
    },
    setAdditionalParametersWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].additionalParameters = {
        ...state.wizards[activeTab].additionalParameters,
        ...omit( action.payload, 'activeTab' ),
      };
    },
    setCompetitorsWizard: ( state, action ) => {
      const activeTab: AUDIENCE_ID = action.payload.activeTab;
      state.wizards[activeTab].competitors = { ...state.wizards[activeTab].competitors, ...omit( action.payload, 'activeTab' ) };
    },
    resetWizard: ( state ) => {
      state = initialState;
      return state;
    },
  },
} );

// Action creators are generated for each case reducer function
export const {
  setSingleWizzardById,
  duplicateWizzardById,
  setWizardIsOpen,
  collapseAllWizards,
  resetAllWizards,
  resetSourceWizard,
  resetPrebuiltAudienceWizard,
  resetMarsBrandsWizard,
  resetOriginWizard,
  resetAdditionalParametersWizard,
  resetCompetitorsWizard,
  setSourceWizard,
  setPrebuiltAudienceWizard,
  setMarsBrandsWizard,
  setOriginWizard,
  setAdditionalParametersWizard,
  setCompetitorsWizard,
  resetWizard,
  setScenario,
  resetWizzardById,
} = wizardSlice.actions;
export default wizardSlice.reducer;
