import { EPartyData } from '../Blade/collapse/OriginCollapse/const';

export const webLinks: Record<number, string> = {
  97:  'mms.com',
  242: 'ethelm.com',
};

export const partyData: Partial<Record<EPartyData, string>> = {
  [EPartyData.firstParty]: '1PD',
  [EPartyData.thirdParty]: '3PD',
};
