import { useSelector } from 'react-redux';
import { useAudienceShortName } from '../AudienceShortName';
import { TextPopover } from '../../TextPopover/TextPopover';
import { AUDIENCE_ID } from '../../Blade/collapse/const';
import { Flex, Spin } from 'antd';
import { selectAudienceComparisonCount } from '../../../store/audienceComparison/selectors';
import { AudienceNamesContext } from '../FilledAudienceComparison';
import { useContext } from 'react';

export const HeaderCaption = () => {
  const audienceComparisonCount = useSelector( selectAudienceComparisonCount );
  const audienceNames = useContext( AudienceNamesContext );

  return (
    <>
      <div className='caption'>
        <div>
          <TextPopover text={audienceNames[AUDIENCE_ID.AUDIENCE1]} />
        </div>
        <div>
          <span className='vs'>VS</span> <TextPopover text={audienceNames[AUDIENCE_ID.AUDIENCE2]} />
        </div>
        {audienceComparisonCount > 2
          ? <div><span className='vs'>VS</span> <TextPopover text={audienceNames[AUDIENCE_ID.AUDIENCE3]} /></div>
          : ''}
      </div>
      {
        Object.values( audienceNames ).includes( 'loading' )
          ? (
            <Flex vertical align='center' justify='center' className='caption-loader'>
              <Spin />
              <span>The request might take a little longer...</span>
            </Flex>
          )
          : null
      }
    </>
  );
};
