import { useDispatch, useSelector } from 'react-redux';
import {
  ISourceWizardInterface,
  IMarsBrandsWizardInterface,
  ICommonWizardInterface,
} from '../../../store/wizzard/wizardSlice';
import AdditionalParametersCollapse from '../collapse/AdditionalParametersCollapse/AdditionalParametersCollapse';
import BrandCollapse from '../collapse/BrandCollapse/BrandCollapse';
import CompetitorsCollapse from '../collapse/CompetitorsCollapse/CompetitorsCollapse';
import { AUDIENCE_ID, COMPETITORS, MARS_BRANDS, PREBUILT_AUDIENCES } from '../collapse/const';
import OriginCollapse from '../collapse/OriginCollapse/OriginCollapse';
import PrebuiltAudienceCollapse from '../collapse/PrebuiltAudienceCollapse/PrebuiltAudienceCollapse';
import SourceCollapse from '../collapse/SourceCollapse/SourceCollapse';
import './CollapseWizard.scss';
import {
  selectWizardAdditionalParametersByTabName,
  selectWizardCompetitorsByTabName,
  selectWizardMarsBrandsByTabName,
  selectWizardOriginByTabName,
  selectWizardPrebuiltAudienceByTabName,
  selectWizardSourceByTabName,
} from '../../../store/wizzard/selectors';

const expandIconStyles = { color: 'rgba(0, 0, 0, 0.45)', fontSize: 16 };

interface ICollapseWizard {
  audience: AUDIENCE_ID;
}

const CollapseWizard = ( { audience }: ICollapseWizard ) => {
  const dispatch = useDispatch();
  const sourceWizard = useSelector( selectWizardSourceByTabName( audience ) );
  const prebuiltAudienceWizard = useSelector( selectWizardPrebuiltAudienceByTabName( audience ) );
  const competitorsWizard = useSelector( selectWizardCompetitorsByTabName( audience ) );
  const marsBrands = useSelector( selectWizardMarsBrandsByTabName( audience ) );
  const origin = useSelector( selectWizardOriginByTabName( audience ) );
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( audience ) );

  // On '+' or '-' click in Collapse
  const handleCollapseChange = (
    reducer: any,
    step:
    | ICommonWizardInterface
    | IMarsBrandsWizardInterface
    | ISourceWizardInterface
    | ICommonWizardInterface,
  ) => {
    dispatch( reducer( { isExpand: !step.isExpand, activeTab: audience } ) );
  };

  return (
    <>
      {/* Step 01: Source*/}
      <SourceCollapse handleCollapseChange={handleCollapseChange} expandIconStyles={expandIconStyles} activeTab={audience} />
      {/* /!*Step 02: Brands*!/*/}
      {sourceWizard.isFinished && marsBrands.isVisible && sourceWizard.value === MARS_BRANDS && (
        <BrandCollapse handleCollapseChange={handleCollapseChange} expandIconStyles={expandIconStyles} activeTab={audience} />
      )}
      {/* Step 03: Origin*/}
      {sourceWizard.value === MARS_BRANDS
				&& marsBrands.isFinished
				&& origin.isVisible
				&& (
				  <OriginCollapse handleCollapseChange={handleCollapseChange} expandIconStyles={expandIconStyles} activeTab={audience} />
				)}
      {/* Step 04: Additional Parameters*/}
      {sourceWizard.value === MARS_BRANDS
				&& marsBrands.isFinished
				&& origin.isFinished
				&& additionalParametersWizard.isVisible
				&& (
				  <AdditionalParametersCollapse
				    handleCollapseChange={handleCollapseChange}
				    expandIconStyles={expandIconStyles}
				    activeTab={audience}
				  />
				)}

      {/* Step 02: Prebuilt Audience*/}
      {sourceWizard.isFinished && prebuiltAudienceWizard.isVisible && sourceWizard.value === PREBUILT_AUDIENCES && (
        <PrebuiltAudienceCollapse handleCollapseChange={handleCollapseChange} expandIconStyles={expandIconStyles} activeTab={audience} />
      )}
      {/* Step 02: Competitors*/}
      {sourceWizard.isFinished && competitorsWizard.isVisible && sourceWizard.value === COMPETITORS && (
        <CompetitorsCollapse handleCollapseChange={handleCollapseChange} expandIconStyles={expandIconStyles} activeTab={audience} />
      )}
    </>
  );
};

export default CollapseWizard;
