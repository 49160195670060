import Icon from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as GenderSvg } from '../../../assets/icons/gender.svg';
import { ReactComponent as CakeSvg } from '../../../assets/icons/cake.svg';
import {
  BankOutlined,
  BulbOutlined,
  CreditCardOutlined,
  CustomerServiceOutlined,
  GlobalOutlined,
  HeartOutlined,
  HomeOutlined,
  KeyOutlined,
  PushpinOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const deatailedIcons: Record<string, any> = {
  'generations':                   <Icon component={CakeSvg} />,
  'gender':                        <Icon component={GenderSvg} />,
  'income':                        <CreditCardOutlined />,
  'ethnic_group':                  <GlobalOutlined />,
  'education':                     <BulbOutlined />,
  'marital_status_in_hh':          <HeartOutlined />,
  'household_size':                <TeamOutlined />,
  'children_present':              <UserOutlined />,
  'urban_or_rural_area':           <HomeOutlined />,
  'metropolitan_statistical_area': <PushpinOutlined />,
  'owner/renter':                  <KeyOutlined />,
  'occupation':                    <BankOutlined />,
  'music_interest_present':        <CustomerServiceOutlined />,
  'niche_persona':                 <TagOutlined />,
};
