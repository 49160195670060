import { configureStore } from '@reduxjs/toolkit';
import wizardReducer from './wizzard/wizardSlice';
import tabsReducer from './tab/tabSlice';
import { audienceComparison } from './audienceComparison/audienceComparisonSlice';

export const store = configureStore( {
  reducer: {
    wizard:    wizardReducer,
    tabs:      tabsReducer,
    audienceComparison,
  },
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
