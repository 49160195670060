export const getCookie = ( name: string ) => {
  const matches = document.cookie.match( new RegExp(
    '(?:^|; )' + name.replace( /([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1' ) + '=([^;]*)'
  ) );
  return matches ? decodeURIComponent( matches[1] ) : null;
};

export const deleteCookie = ( cookieName: string ) => {
  if ( !!getCookie( cookieName ) ) {
    const date = new Date();
    date.setDate( date.getDate() - 1 );
    const domain = document.location.hostname.split( '.' ).reverse().splice( 0, 2 ).reverse().join( '.' );

    document.cookie = `${cookieName}=; expires=${date}; path=/; domain=${domain};`;
  }
};
