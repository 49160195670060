import { Button, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setAudienceComparisonIsLoading, setAudiencesToCompare } from '../../../store/audienceComparison/actions';
import { AUDIENCE_ID } from '../collapse/const';
import Summary from '../Summary/Summary';
import { IBladeProps } from '../types';
import WizardTabs from '../WizardTabs/WizardTabs';
import { selectTabs } from '../../../store/tab/selectors';
import { EScenario, resetWizard, setWizardIsOpen } from '../../../store/wizzard/wizardSlice';

import './Blade.scss';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import {
  selectAppliedAudiencesToCompare,
  selectFinishedTabsIsEqualComparisons,
  selectHasAudiencesToCompare,
} from '../../../store/audienceComparison/selectors';
import { resetTabs } from '../../../store/tab/tabSlice';
import { selectScenario } from '../../../store/wizzard/selectors';

const Blade: React.FC<IBladeProps> = ( { open } ) => {
  const dispatch = useDispatch();
  const tabs = useSelector( selectTabs );
  const selectedScenario = useSelector( selectScenario );
  const isEqualComparisons = useSelector( selectFinishedTabsIsEqualComparisons );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const hasAudiencesToCompare = useSelector( selectHasAudiencesToCompare );

  const checkIsScenarioFinished = () => {
    if ( selectedScenario === EScenario.single ) {
      return tabs[AUDIENCE_ID.AUDIENCE1]?.isFinished;
    }

    return tabs[AUDIENCE_ID.AUDIENCE1]?.isFinished
    && ( tabs[AUDIENCE_ID.AUDIENCE2]?.isFinished || tabs[AUDIENCE_ID.AUDIENCE3]?.isFinished );
  };

  const isApplyEnabled = !isEqualComparisons && checkIsScenarioFinished();

  const handleApply = () => {
    dispatch( setAudienceComparisonIsLoading( true ) );
    dispatch( setWizardIsOpen( false ) );
  };

  const handleAfterOpenChange = ( open: boolean ) => {
    if ( !open ) {
      dispatch( resetTabs() );
      dispatch( resetWizard() );
    }
  };

  const handleClose = () => {
    dispatch( setWizardIsOpen( false ) );
    if ( hasAudiencesToCompare ) {
      dispatch( setAudiencesToCompare( appliedAudienceToCompare ) );
    }
  };

  return (
    <div>
      <Drawer
        rootClassName='blade'
        destroyOnClose
        maskClosable={false}
        title={<TitleWithCaption
          headingLevel={4}
          heading='Audience Parameters'
          headingStyles={{ fontFamily: 'WorkSans-SemiBold' }}
        />}
        placement='right'
        size='large'
        afterOpenChange={handleAfterOpenChange}
        onClose={handleClose}
        open={open}
        extra={
          <>
            <Button type='primary' disabled={!isApplyEnabled} onClick={handleApply}>
              {selectedScenario === EScenario.compare ? 'Compare' : 'Profile'}
            </Button>
          </>
        }
      >
        <div className='steps-content'>
          <Summary />
          <WizardTabs />
        </div>
      </Drawer>
    </div>
  );
};

export default Blade;
