export enum EPartyDataToName {
  FIRST_PARTY_DATA = 'First-Party Data',
  THIRD_PARTY_DATA = 'Third-Party Data'
}

export enum EPartyData {
  none,
  firstParty,
  thirdParty
}
